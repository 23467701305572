
export class IHospital {
    // Hospital (HospitalID, HospitalName, HospitalAddress, State, Country,
    // HospitalEmail, HospitalPhone, ContactName, ContactPhone)
    public	status: string;
    public	hospitalID: string;
    public	hospitalName: string;
    public  hospitalAddress: string;
    public	hospitalPhoneNo: string;
    public  hospitalEmail: string;
    public  country: string;
    public  state: string;
    public  contactName: string;
    public  contactPhoneNo: string;
    public	createdOn: string;
    public	createdByName: string;
    public	lastUpdatedDate: string;
    public	lastUpdatedByName: string;
}
