import { Component, OnInit, OnDestroy, NgZone, ViewEncapsulation } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IUser } from '../../../common/models/IUser';
import { ProfileService } from '../../../services/profile.service';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatDialog } from '@angular/material';
import { HospitalDetailsComponent } from '../hospitalDetails/hospitalDetails.component';
import { DataService } from '../../../services/data.service';
import { ProductsHomeComponent} from '../supplierProducts/productsHome/productsHome.component';
import { RequestsHomeComponent } from '../hospitalRequests/requestsHome/requestsHome.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HospitalMessagesHomeComponent } from '../hospitalMessages/hospitalMessagesHome/hospitalMsgsHome.component';
@Component({
    selector: 'gjs-hospital-home',
    templateUrl: './hospitalHome.component.html',
    styleUrls: ['./hospitalHome.component.scss'],
    providers: [DataService],
    encapsulation: ViewEncapsulation.None
})

export class HospitalHomeComponent implements OnInit, OnDestroy {

  private user = <IUser>{};
  private subscription: Subscription;
  public role = '';
  public loading = false;
  public dynamicTabs = [
    {
      label: 'Hospital Requests',
      component: RequestsHomeComponent
    },
    {
      label: 'Medical Supplies',
      component: ProductsHomeComponent
    },
    {
        label: 'Hospital Profile',
        component: HospitalDetailsComponent
    },
    {
      label: 'Messages',
      component: HospitalMessagesHomeComponent
  }
  ];

  constructor(
      private zone: NgZone, public snackBar: MatSnackBar,
      private translate: TranslateService,
      private profileService: ProfileService, public dialog: MatDialog,
      private dataService: DataService, private router: Router, private route: ActivatedRoute) {
        this.user = this.profileService.setUpProfile();
    }

    ngOnInit(): void {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translate.use(event.lang);
        });
        this.route.paramMap.subscribe(params => {
            this.role = params.get('role');
            console.log('role = ' + params.get('role'));
          });
    }

    ngOnDestroy() {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
    }

}
