import {Component} from '@angular/core';
import { IDonorInformation } from '../../../common/models/IDonorInformation';
import { DonorInformationComponent } from '../../../@theme/components/donorInformation/donorInformation.component';
import { MatDialog } from '@angular/material';
import { DataService } from '../../../services/data.service';


@Component({
  selector: 'gjs-donorsearch',
  templateUrl: './donor.search.html',
  styleUrls: ['./donor.search.scss'],
  providers: [DataService]
})
export class DonorSearchComponent {

    public loading = false;
    public error = null;
    public searchTerm: string;
    constructor(public dialog: MatDialog,
        private dataService: DataService ) {

    }

    init() {
      this.loading = true;
      this.error = null;
    }
    search() {
      this.init();
      try {
        this.dataService.getDonor(this.searchTerm).subscribe(data => {
          this.viewDonorInformationPage(data);
        }, error => {
          this.error = JSON.stringify(error.message);
          this.loading = false;
        });
      } catch (err) {
        this.error = JSON.stringify(err);
      } finally {
        this.loading = false;
      }
    }

    viewDonorInformationPage(donorData) {

        const donor = {} as IDonorInformation;
        donor.requestID = donorData.requestID;
        donor.requiredItem = donorData.requiredItem;
        donor.hospitalID = donorData.hospitalID;
        donor.hospitalEmail = donorData.hospitalEmail;
        donor.status = '';
        const dialogRef = this.dialog.open(DonorInformationComponent, {
          data: donorData,
        });

        dialogRef.afterClosed().subscribe(result => {
        });
      }

}
