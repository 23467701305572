import { Component, OnInit, Inject, NgZone, ViewChild } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IProduct } from '../../../../services/IProduct';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material';
import { DataService } from '../../../../services/data.service';
// tslint:disable-next-line:max-line-length
import { HospitalMessageDetailsComponent } from '../../hospitalMessages/hospitalMessageDetails/hospitalMessageDetails.component';
import { ProfileService } from '../../../../services/profile.service';

@Component({
  selector: 'gjs-product-details',
  templateUrl: './productDetails.component.html',
  styleUrls: ['./productDetails.component.scss'],
  providers: [DataService]
})
export class ProductDetailsComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  isReadOnly = true;
  loading = false;
  supplierID = '';
  supplier = <any>{};
  isCasePanelClosed = true;
  constructor(
    private zone: NgZone, public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ProductDetailsComponent>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: IProduct, private dataService: DataService, private profileService: ProfileService) {
    this.supplierID = data.supplierID;
    if (this.supplierID) {
      this.loadSupplier(this.supplierID);
    }
    }

  loadSupplier(supplierID) {
    this.loading = true;
    this.dataService.getSupplierRecord(encodeURIComponent(this.supplierID))
    .subscribe((supplierData) => {
      this.supplier = supplierData;
      this.loading = false;
    }, err => {
      this.showSnackBar(err.message);
      this.loading = false;
      // this.supplier = {
      //   'status': 'Registered',
      //   'supplierID': '4c59d705-abd7-492c-9f63-268d8ca1613f',
      //   'supplierName': 'Test Supplier',
      //   'supplierPhoneNo': '1232323',
      //   'supplierEmail': 'keerthi@gjsus.onmicrosoft.com',
      //   'supplierAddress': 'Test Supplier, USA',
      //   'supplierType': 'Other Supplier',
      //   'country': 'USA',
      //   'state': 'Virginia',
      //   'createdOn': '2020-4-14 13:12:31',
      //   'createdByName': 'akbar gjsus',
      //   'createdByEmail': 'admin@gjsus.onmicrosoft.com',
      //   'lastUpdatedDate': '',
      //   'lastUpdatedByName': '',
      //   'lastUpdatedByEmail': ''
      // };
    });
  }

  sendMessage() {
    const messageData = this.supplier;
    this.loading = true;
    this.dataService.getHospitalRecord(encodeURIComponent(this.profileService.getGroupId()))
      .subscribe((hospitalData) => {
        console.log('Hospital ID: ' + hospitalData.hospitalID);
        messageData.hospitalID = hospitalData.hospitalID;
        messageData.hospitalName = hospitalData.hospitalName;
        messageData.hospitalEmail = hospitalData.hospitalEmail;
        messageData.status = 'New';
        messageData.date = new Date().toLocaleString();
        messageData.productID = this.data.productID;
        messageData.productName = this.data.productName;
        messageData.messageID = this.genarateID();
        this.loading = false;
        const dialogRef = this.dialog.open(HospitalMessageDetailsComponent, {
          width: '500px',
          data: { ...messageData },
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
            }, 5000);
          }
        });
      }, err => {
        this.showSnackBar(err.message);
        this.loading = false;
      });
  }

  async ngOnInit() {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  showSnackBar(msg) {
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

  genarateID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx'.replace(/[xy]/g, function(c) {
      // tslint:disable-next-line:no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
}
