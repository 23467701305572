import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { HospitalDetailsComponent } from '../hospitalDetails/hospitalDetails.component';
import { DataService } from '../../../services/data.service';
import { IUser } from '../../../common/models/IUser';
import { ProfileService } from '../../../services/profile.service';

@Component({
    selector: 'gjs-requestshome',
    templateUrl: './hospitalRequests.component.html',
    styleUrls: ['./hospitalRequests.component.scss'],
    providers: [DataService]
})

export class HospitalRequestsComponent implements OnInit, OnDestroy {

  public tableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'viewHospitalDetails',
          // tslint:disable-next-line:max-line-length
          title: this.sanitizer.bypassSecurityTrustHtml(` <button'><i style='' title='Click to see hospital details' class="nb-edit"></i></button>`)
        }
      ],
      position: 'right',
    },
    pager : {
      display : true,
      perPage : 5
    },
    columns: {
      status: {
        title: 'Request Status'
      },
      hospitalName: {
        title: 'Hospital Name'
      },
      hospitalPhoneNo: {
        title: 'Hospital Phone No.'
      },
      requiredItem: {
        title: 'Requested Item'
      },
      requiredQuantity: {
        title: 'Required Quantity'
      },
      requiredItemGrade: {
        title: 'Grade/Certification'
      },
      requiredByDate: {
        title: 'Required By'
      },
    }
  };

  public error: any = undefined;
  public browserLang: string;
  private user = <IUser>{};
  private subscription: Subscription;
  public data;
  public loading = false;
  public tableData = [ ];

  constructor(
    private zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog,
    private translate: TranslateService, private profileService: ProfileService,
    private dataService: DataService, private sanitizer: DomSanitizer) {
      this.user = this.profileService.setUpProfile();
    }

    translateMenuItems(): any {
      throw new Error('Method not implemented.');
    }

    loadData() {
      this.loading = true;
      this.dataService.getAllHospitalRequests().subscribe((cases) => {
        this.tableData = cases;
        this.loading = false;
      }, err => {
        this.showSnackBar(err);
        this.loading = false;
        this.showSnackBar('Please refresh the page to see the changes');
      });
    }

    ngOnInit(): void {
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.use(event.lang);
      });
      this.loadData();
    }

    ngOnDestroy() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }

    onCustomAction(event) {
      switch ( event.action) {
        case 'viewHospitalDetails':
          this.viewHospitalDetails(event.data);
          break;
      }
    }

    viewHospitalDetails(requestData) {
      this.loading = true;
      this.dataService.getHospitalRecord(requestData.hospitalID)
        .subscribe((hospitalData) => {
         if (hospitalData) {
          const dialogRef = this.dialog.open(HospitalDetailsComponent, {
            data: {...hospitalData},
            });
            this.loading = false;

            dialogRef.afterClosed().subscribe(result => {
              if (result) {
               this.loading = true;
               setTimeout(() => {
                this.loadData();
               }, 5000);
              }
            });
          }
        }, err => {
          this.showSnackBar(err.message);
          this.loading = false;
        });
        this.loading = false;
      }


      showSnackBar(msg) {
      this.zone.run(() => {
        this.snackBar.open(msg, '', { duration: 3000 });
      });
    }
}
