import { Component, OnInit, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DataService } from '../../../services/data.service';
import { ProfileService } from '../../../services/profile.service';
import { IUser } from '../../../common/models/IUser';
import { ISupplier } from '../../../services/ISupplier';


@Component({
  selector: 'gjs-supplier-details',
  templateUrl: './supplierDetails.component.html',
  styleUrls: ['./supplierDetails.component.scss'],
  providers: [DataService]
})
export class SupplierDetailsComponent implements OnInit {
  public loading = false;
  public data = <ISupplier>{};

  supplierTypes: string[] = ['Manufacturer', 'Distributor', 'Other Supplier'];
  countryList: Array<any> = [
    { name: 'UAE', states: ['Dubai', 'Abu Dhabi', 'Sharjah', 'Umm Al Qaiwain', 'Fujairah', 'Ajman', 'Ras Al Khaimah'] },
    { name: 'USA', states: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
    'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
    'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'] }
  ];
  states: Array<any>;
  supplierID = '';
  constructor(
    private zone: NgZone, public snackBar: MatSnackBar,
    private dataService: DataService, private profileService: ProfileService) {

  }

  async ngOnInit() {
    this.supplierID = this.profileService.getGroupId();
    if (this.supplierID) {
    this.loadData();
    }
  }

  loadData() {
    this.loading = true;
    this.dataService.getSupplierRecord(encodeURIComponent(this.supplierID))
    .subscribe((supplierData) => {
      this.data = supplierData;
      this.loading = false;
      this.states = this.countryList.find(con => con.name === this.data.country).states;
    }, err => {
      this.showSnackBar(err.message);
      this.loading = false;
    });
  }

  changeCountry(event) {
    this.states = this.countryList.find(con => con.name === event.value).states;
  }

  showSnackBar(msg) {
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

  updateSupplier() {
    console.log('Updated Supplier Data:\n' + JSON.stringify(this.data));
    this.dataService.updateSupplier(this.data).subscribe((supplier) => {
      this.showSnackBar('Supplier Information Updated');
      console.log('Data Received:\n' + JSON.stringify(supplier));
    }, err => {
      console.log(err.error.message);
      this.showSnackBar(err.error.message);
    });
  }

}
