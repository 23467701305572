import {Component, OnInit} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'gjs-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private service: MsalService) {
  }

  ngOnInit() {
    this.service.logout();
  }

}
