import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { MENU_ITEMS } from './pages-menu';
import { MenuItem } from '../@theme/custom-nebular/menu-item';
import { IUser } from '../common/models/IUser';
import { ProfileService } from '../services/profile.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'gjs-pages',
  template: `
    <gjs-layout aria-label="menu">
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </gjs-layout>
  `,
})

export class PagesComponent implements OnInit, OnDestroy {
  menu: MenuItem[];
  private subscription: Subscription;
  constructor(
    private translate: TranslateService, private profileService: ProfileService) {
    // this.menu = MENU_ITEMS;
    this.setMenu(this.profileService.setUpProfile());
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateMenuItems();
    });
    this.translateMenuItems();
  }

  translateMenuItems() {
    this.menu.forEach( item => {
      this.translateMenuItem( item );
    });
  }

  translateMenuItem( menuItem: MenuItem ) {
    if ( menuItem.children != null ) {
      menuItem.children.forEach( item => this.translateMenuItem( item ) );
    }

    if (menuItem.data === undefined) {
      menuItem.data = menuItem.title;
      menuItem.title = this.translate.instant( menuItem.title );
    } else {
      menuItem.title = this.translate.instant( menuItem.data );
    }
  }

  setMenu(user: IUser) {
    this.menu = MENU_ITEMS;
      return;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
