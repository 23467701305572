import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../services/profile.service';
import { Subscription } from 'rxjs';
import { BroadcastService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'gjs-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  headerLogoUrl: any;

  @Input() position = 'normal';

  user: any = {};
  role: any;
  userMenu = [
    {
      title: '',
      group: true
   },
  //   {
  //     title: 'Cases',
  //     icon: 'nb-home' ,
  //     link: '/pages/case'
  //  },
  //  {
  //     title: 'Hospital',
  //     icon: 'nb-compose' ,
  //     link: '/pages/hospital'
  //  },
  //  {
  //   title: 'Supplier',
  //   icon: 'nb-paper-plane' ,
  //   link: '/pages/supplier'
  // },
  // {
  //   title: 'Donate',
  //   icon: 'nb-compose' ,
  //   link: 'pages/donation'
  // },
   {
      title: 'Log out',
      icon: 'nb-power-circled',
      link: '/logout'
   }
  ];

  language = '';

  private subscription: Subscription;
  private roleSubscription: Subscription;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private analyticsService: AnalyticsService,
              private translate: TranslateService,
              private profile: ProfileService,
              private broadcastService: BroadcastService,
              private router: Router
              ) {

    this.translate.addLangs(['en', 'ar']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.language = this.translate.getBrowserLang();
  }

  ngOnInit() {
    this.user = { name: this.profile.getDisplayName() ,
                  pic: this.profile.getPicture()};
    this.subscription = this.broadcastService.subscribe('msal:loginSuccess', (payload) => {
      this.user = { name: this.profile.getDisplayName() ,
                    pic: this.profile.getPicture()};
    });

    this.roleSubscription = this.profile.setRole().subscribe(role => {
      this.userMenu[0].title = `${role}`;
    });

    // this.role = this.profile.setRole();
    // if (this.role) {
    // this.userMenu[0].title = `${this.role}`;
    // }

  }

  ngOnDestroy() {
    // this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.roleSubscription) {
      this.roleSubscription.unsubscribe();
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, 'menu-sidebar');
    return false;
  }

  goToHome() {
    this.router.navigate(['/landing']);
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    this.language = language;
  }
}
