import { Component, OnInit, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DataService } from '../../../services/data.service';
import { ProfileService } from '../../../services/profile.service';
import { IHospital } from '../../../services/IHospital';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { IUser } from '../../../common/models/IUser';

@Component({
  selector: 'gjs-add-hospital',
  templateUrl: './addHospital.component.html',
  styleUrls: ['./addHospital.component.scss'],
  providers: [DataService]
})
export class AddHospitalComponent implements OnInit {

  private user = <IUser>{};
  public data = <IHospital>{};
  availableTypes: string[] = ['Available', 'Not Available'];
  Treatments: string[] = ['COVID-19', 'Other'];
  countryList: Array<any> = [
    { name: 'UAE', states: ['Dubai', 'Abu Dhabi', 'Sharjah', 'Umm Al Qaiwain', 'Fujairah', 'Ajman', 'Ras Al Khaimah'] },
    { name: 'USA', states: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
    'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
    'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'] },
    {name: 'INDIA', states: [ 'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttarakhand',
    'Uttar Pradesh',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Lakshadweep',
    'Puducherry']}
  ];
  states: Array<any>;
  loading = false;
  constructor(
    private zone: NgZone, public snackBar: MatSnackBar,
    private dataService: DataService, private profileService: ProfileService) {

  }

  async ngOnInit() {
  }

  changeCountry(event) {
    this.states = this.countryList.find(con => con.name === event.value).states;
  }

  showSnackBar(msg) {
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

  registerHospital() {
    console.log('Hospital Data Sent:\n' + JSON.stringify(this.data));
    this.dataService.registerHospital(this.data).subscribe((hospital) => {
      this.showSnackBar('Hospital Created');
      console.log('Hospital Data Received:\n' + JSON.stringify(hospital));
    }, err => {
      console.log(err.error.message);
      this.showSnackBar('Please try again ' + err.error.message);
    });
  }

}
