import { Component, OnInit, AfterViewInit, NgZone, ChangeDetectorRef, ViewChild, Inject } from '@angular/core';
import { MatSnackBar, MatStepper, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../services/data.service';
import { AppGlobal } from '../../../common/app.global';
import { UploadService } from '../../../services/uploadService';
import { ProfileService } from '../../../services/profile.service';
import { IHospital } from '../../../services/IHospital';
import { IUser } from '../../../common/models/IUser';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';


@Component({
  selector: 'gjs-hospital-details',
  templateUrl: './hospitalDetails.component.html',
  styleUrls: ['./hospitalDetails.component.scss'],
  providers: [DataService, {
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class HospitalDetailsComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  isReadOnly = true;
  public loading = false;

  constructor(
    private zone: NgZone, public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<HospitalDetailsComponent>, public dialog: MatDialog,
    private dataService: DataService, private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: IHospital) {

  }

  async ngOnInit() {
  }

  onCancel(): void {
    this.zone.run(() => {
      this.dialogRef.close();
    });
  }

  showSnackBar(msg) {
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

}
