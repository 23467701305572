import { Component, OnInit, Inject, AfterViewInit, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { MatSnackBar, MatTabChangeEvent, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../services/data.service';
import { AppGlobal } from '../../../common/app.global';
const URL = 'https://casesbcdata.blob.core.windows.net/fileupload/';
import { UploadService } from '../../../services/uploadService';
import { IFileItem } from '../../../common/models/IFileItem';
import { ICase } from '../../../services/ICase';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material';
import { DocViewerComponent } from '../../viewer/docViewerComponent';
import { BlockViewerComponent } from '../../viewer/blockViewerComponent';


@Component({
  selector: 'gjs-addcase',
  templateUrl: './AddCase.component.html',
  styleUrls: ['./AddCase.component.scss'],
  providers: [DataService, {
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class AddCaseComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper: MatStepper;
  isReadOnly = false;
  public roles;
  public role;

  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  fileToUpload: File = null;

  public loading = false;
  status: string;
  isEditable = true;
  isRegStepEditable = false;
  isRecovered = false;
  isActive = false;
  isVacEditable = false;
  isDiedEditable = false;
  ICaseData: ICase;
  genders: string[] = ['Male', 'Female', 'Other'];
  yesorno: string[] = ['Yes', 'No'];
  casesStatus: string[] = ['Confirmed', 'Active', 'Recovered', 'Vaccinated', 'Died'];
  countries: string[] = ['UAE', 'US'];
  contactTypes: string[] = ['Travel', 'Home', 'Shopping', 'Doctor`s Visit', 'Emergency Room', 'Hotel', 'Restaurant', 'Other' ];
  countryList: Array<any> = [
    { name: 'UAE', states: ['Dubai', 'Abu Dhabi', 'Sharjah', 'Umm Al Qaiwain', 'Fujairah', 'Ajman', 'Ras Al Khaimah'] },
    { name: 'USA', states: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
    'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
    'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'] }
  ];
  states: Array<any>;
  constructor(
    private zone: NgZone, public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddCaseComponent>, public dialog: MatDialog,
    private http: HttpClient, private dataService: DataService, private uploadService: UploadService,
    private config: AppGlobal, private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: ICase) {

    // cases Start
    this.ICaseData = data;
    this.status = data.status;
    if (this.status !== 'New') {
      this.isReadOnly = true;
      this.data.caseStatus = this.data.status;
    }
    data.reportedDate = this.fixDate(data.reportedDate);
    // cases End
  }
  private caseBlockHistory = [];

  async ngOnInit() {
    if (this.status !== 'New' && this.ICaseData.patientID) {
      this.loading = true;
      this.dataService.getCaseHistory(this.ICaseData.patientID).subscribe((cases) => {
        // console.log(cases);
        this.caseBlockHistory = cases;
        this.loading = false;
      }, err => {
        console.log(err.error.message);
        this.showSnackBar(err.error.message);
        this.loading = false;
      });
    }
  }

  ngAfterViewInit() {
    switch (this.status) {
      case 'Confirmed': this.repeat(1);
        if (this.data.confirmedDate) {
        this.isRegStepEditable = true;
        }
        break;
      case 'Active': this.repeat(2);
        this.isActive = true;
        break;
      case 'Recovered':
        this.repeat(3);
        this.isRegStepEditable = true;
        this.isRecovered = true;
        break;
      case 'Vaccinated': this.repeat(4);
        this.isRegStepEditable = true;
        this.isRecovered = true;
        this.isVacEditable = true;
        break;
        case 'Died': this.repeat(5);
        this.isRegStepEditable = true;
        this.isRecovered = true;
        this.isVacEditable = true;
        this.isDiedEditable = true;
        this.isEditable = true;
        break;
    }
    this.cdr.detectChanges();
  }

  repeat(num) {
    for (let i = 0; i < num; i++) { this.stepper.next(); }
  }

  changeCountry(event) {
    // console.log('Onchange Event ', event.value);
    this.states = this.countryList.find(con => con.name === event.value).states;
  }

  openDocViewer(src) {
    this.dialog.open(DocViewerComponent, {
      width: '900px',
      height: '800px',
      data: {
        src: src
      },
      panelClass: 'custom-modalbox'
    });
  }

  openBlockViewer(blockStatus) {
    let results = [];
    const searchField = 'status';
    const searchVal = blockStatus;
   // console.log('Block of status: ' + blockStatus);
    if (this.caseBlockHistory && blockStatus !== 'ViewAllBlocks') {
      for (let i = 0; i < this.caseBlockHistory.length; i++) {
        if (this.caseBlockHistory[i][searchField] === searchVal) {
          results.push(this.caseBlockHistory[i]);
        }
      }
    }
     if (blockStatus === 'ViewAllBlocks' ) {
      results = this.caseBlockHistory;
     }
     // console.log(results);
      this.dialog.open(BlockViewerComponent, {
        width: '900px',
        height: '800px',
        data: results,
        panelClass: 'custom-modalbox'
      });

  }

  onCancel(): void {
    this.dialogRef.close();
  }

  showSnackBar(msg) { // Popup Message on Send 'Serve Order'
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

  registerCase() {
    this.data.status = this.data.caseStatus;
    console.log('Registered Data Sent:\n' + JSON.stringify(this.data));
    this.dataService.registerCase(this.data).subscribe((cases) => {
      this.showSnackBar('Case Created');
      this.dialogRef.close();
      console.log('Registered Data Received:\n' + JSON.stringify(cases));
    }, err => {
      console.log(err.error.message);
      this.showSnackBar(err.error.message);
    });
    // this.notify(this.data);
  }

  onStatusChange(newstatus) {
    this.data.status = this.data.caseStatus;
    if (!this.data.reportedBy) {
      this.data.reportedBy = ''; // 'Demo Officer';
    }
    if (!this.data.comments) {
      this.data.comments = '';
    }
    if (!this.data.isBloodPlasmaVolunteer) {
      this.data.isBloodPlasmaVolunteer = '';
    }
    console.log('Requested Data:\n' + JSON.stringify(this.data));
    // tslint:disable-next-line:max-line-length
    this.dataService.updateStatus(this.data.patientID, this.data.status, this.data.reportedBy, this.data.comments, this.data.isBloodPlasmaVolunteer).subscribe((cases) => {
      this.showSnackBar('Status Updated');
      this.dialogRef.close();
      console.log('Data Received:\n' + JSON.stringify(cases));
    }, err => {
      console.log(err.error.message);
      this.showSnackBar(err.error.message);
    });
  }

  fixDate(date) {
    if (!date) {
      return date;
    }
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

}
