import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IUser } from '../../../common/models/IUser';
import { ProfileService } from '../../../services/profile.service';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AddCaseComponent } from '../addCase/addCase.component';
import { DataService } from '../../../services/data.service';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { timeout } from 'q';
import { ICase } from '../../../services/ICase';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'gjs-casehome',
    templateUrl: './caseHome.component.html',
    styleUrls: ['./caseHome.component.scss'],
    providers: [DataService]
})
export class CaseHomeComponent implements OnInit, OnDestroy {

  public error: any = undefined;
  public citationSearch: FormGroup;
  public browserLang: string;
  public role;
  public roles;
  private user = <IUser>{};
  private subscription: Subscription;
  public data;
  public loading = false;

  public tableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    pager : {
      display : true,
      perPage : 7
    },
    columns: {
      patientID: {
        title: 'Patient #'
      },
      status: {
        title: 'Status'
      },
      reportedDate: {
        title: 'Reported Date',
        type: 'date',
        valuePrepareFunction: (date) => {
          if (date) {
          return new DatePipe('en-US').transform(date, 'MM-dd-yyyy');
          }
          return null;
          },
      },
      reportedBy: {
        title: 'Reported By'
      },
      caseNumber: {
        title: 'Case Number'
      },
      country: {
        title: 'Country'
      },
      state: {
        title: 'State'
      }
    }
  };
// Registered ->
  public tableData = [ ];

  constructor(
      private zone: NgZone, public snackBar: MatSnackBar,
      private translate: TranslateService,
      private profileService: ProfileService, public dialog: MatDialog,
      private dataService: DataService) {
        this.user = this.profileService.setUpProfile();
        // this.profileService.getRole().subscribe(role => {
        //   this.role = role;
        // });
        this.roles = {};
    }

    translateMenuItems(): any {
      throw new Error('Method not implemented.');
    }

    loadData() {
      this.loading = true;
      this.dataService.getCases().subscribe((cases) => {
       // console.log(cases);
        this.tableData = cases;
        this.loading = false;
      }, err => {
        this.showSnackBar(err.error.message);
        this.loading = false;
        this.showSnackBar('Please refresh the page to see the changes');
      });
    }

    ngOnInit(): void {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translate.use(event.lang);
        });
        this.loadData();

    }

    ngOnDestroy() {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
    }

    register() {
      const data: ICase = {
        status: 'New',
        patientID: '',
        patientDOB: '',
        age: '',
        gender: '',
        reportedBy: '',
        recoveredOnDate: '',
        confirmedDate: '',
        activeDate: '',
        contactType: '',
        labConfirmationBy: '',
        comments: '',
        reportedDate: new Date().toISOString().split('T')[0] ,
        dateOfVaccination: '',
        dateOfDeath: '',
        caseNumber:  null ,
        caseStatus:  'Confirmed' ,
        isBloodPlasmaVolunteer: '',
        country:  null ,
        state:  null ,
        createdOn: '',
        createdByName: '',
        lastUpdatedDate: '',
        lastUpdatedByName: '',
          };
      const dialogRef = this.dialog.open(AddCaseComponent, {
        width: '550px',
        height: '100%',
        position: { right: '0', top: '0', bottom: '0'},
        data: data,
        // disableClose: true
        });
        dialogRef.afterClosed().subscribe(newCase => {
          if (newCase) {
            this.loading = true;
           setTimeout(() => {
            this.loadData();
           }, 5000);
          }
        });
    }

    viewCaseDetails(event) {
      const dialogRef = this.dialog.open(AddCaseComponent, {
        width: '1050px',
        height: '100%',
        position: { right: '0', top: '0', bottom: '0'},
        data: {...event.data, role: this.role},
        // disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
         this.loading = true;
         setTimeout(() => {
          this.loadData();
         }, 5000);
        }
      });
    }

    showSnackBar(msg) { // Popup Message on Send 'Serve Order'
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }
}
