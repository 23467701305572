import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gjs-pagenotfound',
  styleUrls: ['./pageNotFound.component.scss'],
  templateUrl: './pageNotFound.component.html',
})
export class PageNotFoundComponent implements OnInit {

  public msg = '404 Page Not Found';
  public desc = 'The page you were looking for does not exist';
  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParams => {
      this.msg = queryParams.get('error') || this.msg;
      this.desc = queryParams.get('desc') || this.desc;
    });
  }

  goToHome() {
    const navigationExtras: NavigationExtras = {
      skipLocationChange: true
    };

    this.router.navigate(['/pages', 'landing'], navigationExtras); // redirecting to home page
  }
}
