import { Component, OnInit, Inject, AfterViewInit, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material';
import { DataService } from '../../../../services/data.service';
import { IProduct } from '../../../../services/IProduct';
import * as RequestClient from 'request';

@Component({
  selector: 'gjs-add-product',
  templateUrl: './addProduct.component.html',
  styleUrls: ['./addProduct.component.scss'],
  providers: [DataService, {
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class AddProductComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper: MatStepper;
  isReadOnly = false;
  public loading = false;
  availableTypes: string[] = ['Available', 'Not Available'];
  productCodes: string[] = ['Manufacturer', 'Distributor', 'Other Supplier'];
  countryList: Array<any> = [
    { name: 'UAE', states: ['Dubai', 'Abu Dhabi', 'Sharjah', 'Umm Al Qaiwain', 'Fujairah', 'Ajman', 'Ras Al Khaimah'] },
    { name: 'USA', states: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
    'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
    'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'] }
  ];
  states: Array<any>;
  isSubmitDisabled = true;
  showFDAImage = false;
  showNotFDAImage = false;
  fdaloading = false;
  isFDAApproved = '';

  constructor(
    private zone: NgZone, public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddProductComponent>, public dialog: MatDialog,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: IProduct) {
    if (data.status !== 'New') {
      this.isReadOnly = true;
    }
  }

  async ngOnInit() {
  }

  ngAfterViewInit() {
  }

  changeCountry(event) {
    this.states = this.countryList.find(con => con.name === event.value).states;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  showSnackBar(msg) {
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

  registerProduct() {
    this.data.status = 'Registered';
    this.data.productUDI = '';
    console.log(' Data Sent:\n' + JSON.stringify(this.data));
    this.dataService.registerProduct(this.data).subscribe((product) => {
      this.showSnackBar('Product Created');
      this.dialogRef.close();
      console.log('Data Received:\n' + JSON.stringify(product));
    }, err => {
      console.log(err.error.message);
      this.showSnackBar(err.error.message);
    });
  }

  updateProduct() {
    this.data.productUDI = '';
    console.log('Updated Product Data:\n' + JSON.stringify(this.data));
    this.dataService.updateProduct(this.data).subscribe((product) => {
      this.showSnackBar('Product Information Updated');
      this.dialogRef.close();
      console.log('Received:\n' + JSON.stringify(product));
    }, err => {
      console.log(err.error.message);
      this.showSnackBar(err.error.message);
    });
  }

  diValueChange(event) {
    this.isSubmitDisabled = true;
    this.showNotFDAImage = false;
    this.showFDAImage = false;
    this.fdaloading = true;
    RequestClient( {
      url: 'https://accessgudid.nlm.nih.gov/api/v2/devices/lookup.json?di=' + event.target.value,
      method: 'GET'
    }, (err, xres, body) => {
      if (err ) {
        console.log('err' + err);
        setTimeout(() => {
          this.fdaloading = false;
        }, 1000);
        this.showNotFDAImage = false;
        this.showFDAImage = false;
        this.isSubmitDisabled = false;
      } else if ( xres && xres.statusCode !== 200) {
        setTimeout(() => {
          this.fdaloading = false;
        }, 1000);
        this.showNotFDAImage = true;
        this.showFDAImage = false;
        this.isSubmitDisabled = false;
        this.data.isFDAApproved = 'Not Approved';
        console.log('Device is not FDA approved');
      } else {
        setTimeout(() => {
          this.fdaloading = false;
        }, 1000);
        this.showFDAImage = true;
        this.showNotFDAImage = false;
        this.isSubmitDisabled = false;
        this.data.isFDAApproved = 'Approved';
        console.log('Device is FDA approved');
      }
    });
  }

  fixDate(date) {
    if (!date) {
      return date;
    }
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

}
