import { Component, Inject, ElementRef, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, AUTOCOMPLETE_OPTION_HEIGHT } from '@angular/material';
import { IPFS } from '../../ipfs';

@Component({
  selector: 'gjs-case-pdfviewer',
  template: `
    <div mat-dialog-content align="right">
    <button type="button" (click)="OK()" class="btn btn-warning btn-s">Close</button>
    </div>
    <pdf-viewer [src]="pdfSrc" [render-text]="true" style="display: block;"></pdf-viewer>
    <!-- <span *ngIf="ipfsSrc"><iframe [src]="ipfsSrc" width="800" height="800">
        <p>Your browser does not support iframes.</p>
    </iframe></span>
    -->
    <div *ngIf='loading'>
    <mat-progress-spinner
    class="spinner"
    [color]="'primary'"
    [mode]="'indeterminate'">
  </mat-progress-spinner>
   </div>
    `,
  styleUrls: ['./viewer.component.scss'],
})

export class DocViewerComponent implements AfterViewInit, OnInit {
  pdfSrc;
  hash;
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<DocViewerComponent>, @Inject(IPFS) private ipfs,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pdfSrc = null;
    if (data && data.src) {
      this.hash = data.src;
    }
  }

  ngAfterViewInit() {
    this.setPDFSrc(this.hash);
  }

  async setPDFSrc(hash) {
    this.loading = true;
    if (hash) {
      const fileBuffer = await this.ipfs.cat(hash);
      this.pdfSrc = fileBuffer.toString();
    }
    this.loading = false;
  }

  ngOnInit() {

  }

  OK(): void {
    this.dialogRef.close();
  }

}
