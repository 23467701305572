import { LogLevel } from 'msal';

// Logger callback for MSAL
export function loggerCallback(logLevel, message, piiEnabled) {
    console.log(message);
}

// tslint:disable-next-line:max-line-length
export const protectedResourceMap: [string, string[]][] =
[ ['https://coronachainapi.azurewebsites.net/api',
    [ 'api://8ecb52aa-ef26-4c2b-b898-79bd6543b6d5/Read.API']
],
 ['https://graph.microsoft.com/v1.0/me', ['user.read']] ];

 export const adminRole = 'f8a1f8bc-b433-48b1-9c39-fb643691c28e';
export const hospitalAdminRole = '61a853e8-0c0e-4e60-9bc2-3095de5e7582';
export const supplierAdminRole = '';
export const userRole = 'de001d4a-84d0-4449-8ece-11e18d496d3a';

export const appMsalConfig = {
    authority: 'https://login.microsoftonline.com/CORONASUPPLYCHAINB2B.onmicrosoft.com/',
    consentScopes: ['api://8ecb52aa-ef26-4c2b-b898-79bd6543b6d5/Read.API'],
    clientID: '40e5c4b4-7f02-43c9-bbec-b12bfe3650ed',
    popUp: false,
    validateAuthority : true,
    logger: loggerCallback,
    protectedResourceMap: protectedResourceMap,
    unprotectedResources: ['/assets/i18n/en.json', '/assets/i18n/es.json'],
    level: LogLevel.Verbose,
};

export const AppSettingConstants = {
    blobUrl: 'https://willsbcdata.blob.core.windows.net/',
    container: 'fileupload',
    // tslint:disable-next-line:max-line-length
    sendEmailFlow: 'https://prod-16.westus2.logic.azure.com:443/workflows/5380f1be43174215af3ad1e5b5aa4825/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=sm9MoBA32C7kJTkM8eE3AJwvwxUc-RB3dOMF-X4tAaI'
};

// tslint:disable-next-line:max-line-length
export const notifyWillsBCUsersFlow = 'https://prod-19.eastus.logic.azure.com:443/workflows/b6fad8bb10814b3187b06f214f0c808f/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=1taj7c_er3HNuTV5eKj0hhlQ3-SFt9OTaiAbLC87aTc';
