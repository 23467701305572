import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { IUser } from '../common/models/IUser';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ProfileService {

    private subject: ReplaySubject<string>;
    constructor (private msalService: MsalService, private http: HttpClient) {
    }

    setUpProfile(): any {

        const userAD = this.msalService.getUser();
        const idToken = (<any>userAD.idToken);
        console.log('user ' + JSON.stringify(userAD));
        return <IUser> {
            id: idToken.oid,
            email: this.getEmail(idToken),
            displayName: userAD.name,
            fullName: userAD.name, // idToken.given_name || ' ' || idToken.family_name,
            firstName: idToken.given_name,
            lastName: idToken.family_name
        };
    }

    getUserToken() {
        return this.msalService.getCachedTokenInternal([]);
    }

    getEmail(token) {
        if (token.preferred_username) {
            return token.preferred_username;
            }
        if (!token.emails) {
            return '';
        }
        if (token.emails.length === 0 ) {
            return '';
        }
        if (token.emails[0]) {
            return token.emails[0];
        }
    }


    public getDisplayName() {
        const userAD = this.msalService.getUser();
        return userAD.name;
    }

    public getPicture() {
        return '';
    }

    public initRole() {
        this.subject = new ReplaySubject<string>(1);
        const userAD = this.msalService.getUser();
        const idToken = (<any>userAD.idToken);
        const roles: string[] = idToken.roles;

        if (!roles || roles.length <= 0 ) {
            this.subject.next('');
            return;
        }
        switch(roles[0]) {
            case 'CoronaChainAdmin':
                this.subject.next('Administrator');
                break;
            case 'CoronaChainHospitalAdmin':
                this.subject.next('Hospital Admin');
                break;
            case 'CoronaChainSupplierAdmin':
                this.subject.next('Supplier Admin');
                break;
            case 'CoronaChainUser':
                this.subject.next('User');
                break;
            default:
                this.subject.next('');
                break;
        }
    }

    public setRole(): Observable<string> {
        if (!this.subject) {
            this.initRole();
        }
        return this.subject.asObservable();
    }

    // public setRole() {
    //     const userAD = this.msalService.getUser();
    //     const idToken = (<any>userAD.idToken);
    //     const groups: string[] = idToken.groups;
    //     if (groups.includes('0e492f3e-f85b-4437-a7e7-9d1f799e0563')) {
    //         return 'Hospital Admin';
    //     } else if (groups.includes('daf03c38-f794-4c7a-80aa-a1db58caed1ad')) {
    //         return 'Supplier Admin';
    //     } else if (groups.includes('e5a010c9-a0aa-4b89-977a-26d63330f7c9')) {
    //         return 'Case Admin';
    //     } else {
    //         return '';
    //     }
    // }

    public getUserInfo() {
        const userAD = this.msalService.getUser();

        return userAD;
    }

    private getUserId() {
        const userAD = this.msalService.getUser();

        if (!userAD) {
            return 'user';
        }
        const idToken = (<any>userAD.idToken);
        return idToken.oid;
    }

    public isUserAuthenticated() {
        if (!this.msalService.getUser()) {
            return false;
        }
        return true;
    }

    public getGroupId() {
        const userAD = this.msalService.getUser();
        const idToken = (<any>userAD.idToken);
        const groups: string[] = idToken.groups;

        return groups.length > 0 ? groups[0] : '';
    }
}
