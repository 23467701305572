export class IProduct {
    public	status: string;
    public  supplierID: string;
    public  supplierName: string;
    public	productID: string;
    public	productName: string;
    public  productCode: string;
    public	productGrade: string;
    public  productDI: string;
    public  productUDI: string;
    public  onHandQuantity: string;
    public  productionEstimatedQuantity: string;
    public  productionEstimatedDate: string;
    public  isFDAApproved: string;
    public	createdOn: string;
    public	createdByName: string;
    public	lastUpdatedDate: string;
    public	lastUpdatedByName: string;
}
