import { Component, OnInit, OnDestroy, NgZone, ViewEncapsulation } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IUser } from '../../../common/models/IUser';
import { ProfileService } from '../../../services/profile.service';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AddHospitalComponent } from '../addHospital/addHospital.component';
import { DataService } from '../../../services/data.service';
import { AddSupplierComponent} from '../addSupplier/addSupplier.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'gjs-admin-home',
    templateUrl: './adminHome.component.html',
    styleUrls: ['./adminHome.component.scss'],
    providers: [DataService],
    encapsulation: ViewEncapsulation.None
})

export class AdminHomeComponent implements OnInit, OnDestroy {

  private user = <IUser>{};
  private subscription: Subscription;
  public role = '';
  public loading = false;
  public dynamicTabs = [
    {
      label: 'Add Hospital',
      component: AddHospitalComponent
    },
    {
        label: 'Add Supplier',
        component: AddSupplierComponent
    }
  ];

  constructor(
      private zone: NgZone, public snackBar: MatSnackBar,
      private translate: TranslateService,
      private profileService: ProfileService, public dialog: MatDialog,
      private dataService: DataService, private router: Router, private route: ActivatedRoute) {
        this.user = this.profileService.setUpProfile();
    }

    ngOnInit(): void {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translate.use(event.lang);
        });
        this.route.paramMap.subscribe(params => {
            this.role = params.get('role');
            console.log('role = ' + params.get('role'));
          });
    }

    ngOnDestroy() {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
    }

}
