import { Injectable } from '@angular/core';
import { AppSettingConstants } from '../../settings/appsettings';
import { notifyWillsBCUsersFlow } from '../../settings/appsettings';
@Injectable()
export class AppGlobal {


    public container: string;
    public blobUrl: string;
    public notifyWillsBCUsersFlow: string;
    public sendEmailFlow: string;

    constructor() {
        this.container = AppSettingConstants.container;
        this.blobUrl = AppSettingConstants.blobUrl;
        this.notifyWillsBCUsersFlow = notifyWillsBCUsersFlow;
        this.sendEmailFlow = AppSettingConstants.sendEmailFlow;
    }

}
