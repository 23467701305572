import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'maskString' })
export class MaskStringPipe implements PipeTransform {
    transform(value: string): string {
      if ( !value) {
        return ;
      }
        if ( value.length < 4) {
          return value;
        }
        return 'X'.repeat(value.length - 2) + value.slice(-2);
      }
}
