import { TranslateModule } from '@ngx-translate/core';
import { NgModule, ModuleWithProviders } from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaskStringPipe} from '../@theme/pipes/maskString.pipe';
import {MaskDatePipe} from '../@theme/pipes/maskDate.pipe';
@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
    ],
    exports: [
        CommonModule,
        TranslateModule,
        MaskStringPipe,
        MaskDatePipe
    ],
    declarations: [MaskStringPipe, MaskDatePipe]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule
        };
    }
}
