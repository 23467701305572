import { DataService } from '../../../../services/data.service';
import { Component, OnInit, ViewChild, Inject, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IMessage } from '../../../../services/IMessage';
import { MatSnackBar, MatTabChangeEvent, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProfileService } from '../../../../services/profile.service';
import { IUser } from '../../../../common/models/IUser';
@Component({
  selector: 'gjs-suppmessage-details',
  templateUrl: './supplierMessageDetails.component.html',
  styleUrls: ['./supplierMessageDetails.component.scss'],
  providers: [DataService]
})
export class SupplierMessageDetailsComponent implements OnInit {
  public message: any;
  isReadOnly = false;
  public loading = false;
  public user = <IUser>{};
  constructor( private zone: NgZone, public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SupplierMessageDetailsComponent>, public dialog: MatDialog,
    private readonly dataService: DataService, private route: ActivatedRoute, private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: IMessage) {
      this.user = this.profileService.setUpProfile();
      if (data.status !== 'New') {
        this.isReadOnly = true;
      }

    }

  ngOnInit() {
    // this.dataService.getMessage(this.route.snapshot.paramMap.get('messageId')).subscribe((data: any) => {
    //   this.message = data;
    //   // console.log(this.images);
    //   // console.log(data);
    // });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  showSnackBar(msg) { // Popup Message on Send 'Serve Order'
  this.zone.run(() => {
    this.snackBar.open(msg, '', { duration: 3000 });
  });
}

reply() {
  this.data.status = 'Supplier Replied';
  this.data.repliedDate =  new Date().toLocaleString();
  console.log('Updated Message:\n' + JSON.stringify(this.data));
  this.dataService.replyMessage(this.data).subscribe((recs) => {
    this.showSnackBar('Message Replied');
    this.loading = false;
    this.dialogRef.close();
    console.log('Data Received:\n' + recs);
  }, err => {
    this.loading = false;
    console.log(err.error.message);
    this.showSnackBar('Please try again ' + err.error.message);
  });
}

fixDate(date) {
  if (!date) {
    return date;
  }
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();
  if (month.length < 2) {
    month = '0' + month;
  }

  if (day.length < 2) {
    day = '0' + day;
  }
  return [year, month, day].join('-');
}

}
