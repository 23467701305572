import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'gjs-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by hidden-xs">{{ 'FOOTER' | translate}}
    <b><a href="http://www.justchain.us/" target="_blank"> JUSTCHAIN</a></b> {{currentYear}}</span>
  `,
})
export class FooterComponent {
  currentYear: number = new Date().getFullYear();
}

