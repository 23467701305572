import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { LandingComponent } from '../@theme/components/landing/landing.component';
import { HomeComponent } from './home/home.component';
import { CaseHomeComponent } from './case/caseHome/caseHome.component';
import { HospitalHomeComponent } from './hospital/hospitalHome/hospitalHome.component';
import { SupplierHomeComponent } from './supplier/supplierHome/supplierHome.component';
import { PageNotFoundComponent } from '../@theme/components/pageNotFound/pageNotFound.component';
// import { DonationHomeComponent as DonationComponent } from './donation/donationHome/donationHome.component';
import { DonationHomeComponent } from '../@theme/components/donationHome/donationHome.component';
// import { ProductsHomeComponent } from './supplierProducts/productsHome/productsHome.component';
import { AdminHomeComponent } from './admin/adminHome/adminHome.component';
import { DonorSearchComponent } from './hospital/donorSearch/donor.search';
import { SupplierMessagesHomeComponent } from './supplier/supplierMessages/supplierMessagesHome/suppMsgsHome.component';
const routes: Routes = [{
    path: '',
    component: PagesComponent,
    // canActivate: [AuthGuard], //uncomment this after fixing the auth.guard.ts,which is not working for AD users
    // data: { roles: ['user', 'judge','admin'] },
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'admin',
        component: AdminHomeComponent,
        data: {
          expectedRole: ['Super Admin']
        }
      },
      {
        path: 'search',
        component: DonorSearchComponent,
        data: {
          expectedRole: ['Hospital Admin']
        }
      },
      // {
      //   path: 'case',
      //   component: CaseHomeComponent,
      //   data: {
      //     expectedRole: ['Case Admin']
      //   }
      // },
      {
        path: 'hospital',
        component: HospitalHomeComponent,
        data: {
          expectedRole: ['Hospital Admin']
        }
      },
      {
        path: 'supplier',
        component: SupplierHomeComponent,
        data: {
          expectedRole: ['Supplier Admin']
        }
      },
      {
        path: 'supplier/messages',
        component: SupplierMessagesHomeComponent,
        data: {
          expectedRole: ['Supplier Admin']
        }
      },
      // {
      //   path: 'product',
      //   component: ProductsHomeComponent,
      //   data: {
      //     expectedRole: ['Supplier Admin']
      //   }
      // },
      // {
      //   path: 'donation',
      //   component: DonationComponent,
      // },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      }]
    },
    {
      path: 'landing',
      component: LandingComponent
    },
    {
      path: 'pageNotFound',
      component: PageNotFoundComponent
    },
    {
      path: 'donate',
      component: DonationHomeComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
