import { Component, OnInit, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DataService } from '../../../services/data.service';
import { ISupplier } from '../../../services/ISupplier';

@Component({
  selector: 'gjs-add-supplier',
  templateUrl: './addSupplier.component.html',
  styleUrls: ['./addSupplier.component.scss'],
  providers: [DataService]
})
export class AddSupplierComponent implements OnInit {

  public data = <ISupplier>{};
  availableTypes: string[] = ['Available', 'Not Available'];
  supplierTypes: string[] = ['Manufacturer', 'Distributor', 'Other Supplier'];
  countryList: Array<any> = [
    { name: 'UAE', states: ['Dubai', 'Abu Dhabi', 'Sharjah', 'Umm Al Qaiwain', 'Fujairah', 'Ajman', 'Ras Al Khaimah'] },
    { name: 'USA', states: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
    'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
    'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'] }
  ];
  states: Array<any>;
  loading = false;

  constructor(
    private zone: NgZone, public snackBar: MatSnackBar,
    private dataService: DataService) {

  }

  async ngOnInit() {
  }

  changeCountry(event) {
    this.states = this.countryList.find(con => con.name === event.value).states;
  }

  showSnackBar(msg) {
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

  registerSupplier() {
    console.log('Supplier Data Sent:\n' + JSON.stringify(this.data));
    this.dataService.registerSupplier(this.data).subscribe((supplier) => {
      this.showSnackBar('Supplier Created');
      console.log('Supplier Data Received:\n' + JSON.stringify(supplier));
    }, err => {
      console.log(err.error.message);
      this.showSnackBar(err.error.message);
    });
  }

}
