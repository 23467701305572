import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IUser } from '../../../common/models/IUser';
import { ProfileService } from '../../../services/profile.service';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatDialog } from '@angular/material';
import { DataService } from '../../../services/data.service';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { timeout } from 'q';
import { IHospitalRequest } from '../../../services/IHospitalRequest';
import { DatePipe } from '@angular/common';
import { Router, NavigationExtras } from '@angular/router';
import { DonorInformationComponent } from '../donorInformation/donorInformation.component';
import { DomSanitizer } from '@angular/platform-browser';
import { IDonorInformation } from '../../../common/models/IDonorInformation';
import { IHospital } from '../../../services/IHospital';
import { AddRequestsComponent } from '../addRequests/addRequests.component';
import { date } from 'azure-storage';

@Component({
    selector: 'gjs-donationhome',
    templateUrl: './donationHome.component.html',
    styleUrls: ['./donationHome.component.scss'],
    providers: [DataService]
})
export class DonationHomeComponent implements OnInit, OnDestroy {
    public error: any = undefined;
    public citationSearch: FormGroup;
    public browserLang: string;
    private user = <IUser>{};
    private subscription: Subscription;
    public data;
    public view = 'table';

    items = [];
    placeholders = [];
    pageSize = 10;
    pageToLoadNext = 0;
    loading = false;

    public tableSettings = {
      actions: {
        add: false,
        edit: false,
        delete: false
      },
      pager : {
        display : true,
        perPage : 20
      },
      columns: {
        Action:
        {
          title: 'Action',
          type: 'html',
          valuePrepareFunction: (cell, row) => {
            if (row.status === 'Closed') { // green
              // tslint:disable-next-line:max-line-length
              return this.sanitizer.bypassSecurityTrustHtml(`<button disabled style='background-color:rgb(17, 221, 128); opacity: 0.5; font-weight: 500;line-height:1.25;padding: 0.55rem 0.5rem;font-size: 1.25rem;border-radius: 0.375rem;border: 2px solid transparent;vertical-align: middle;display: inline-block;text-shadow: none;font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;'>Closed</button> `);
            } else if (row.status === 'Fulfilled') { // green
              // tslint:disable-next-line:max-line-length
              return this.sanitizer.bypassSecurityTrustHtml(` <button disabled style='background-color:rgb(17, 221, 128); opacity: 0.5; font-weight: 500;line-height:1.25;padding: 0.55rem 0.5rem;font-size: 1.25rem;border-radius: 0.375rem;border: 2px solid transparent;vertical-align: middle;display: inline-block;text-shadow: none;font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;'>Fulfilled</button> `);
            } else if (row.status === 'Pending') { // Orange
              // tslint:disable-next-line:max-line-length
              return this.sanitizer.bypassSecurityTrustHtml(` <button disabled style='background-color:orange; opacity: 0.5; font-weight: 500;line-height:1.25;padding: 0.55rem 0.5rem;font-size: 1.25rem;border-radius: 0.375rem;border: 2px solid transparent;vertical-align: middle;display: inline-block;text-shadow: none;font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;'>Donate</button> `);
            } else if (row.status === 'Partly Fulfilled' || row.status === 'Partially Pledged') { // Yellow
              // tslint:disable-next-line:max-line-length
              return this.sanitizer.bypassSecurityTrustHtml(` <button onClick='popup("${row.requestID}")' style='background-color:#e8e816;font-weight: 500;line-height:1.25;padding: 0.55rem 0.5rem;font-size: 1.25rem;border-radius: 0.375rem;border: 2px solid transparent;vertical-align: middle;display: inline-block;text-shadow: none;font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;'>Donate</button> `);
            } else {
              // tslint:disable-next-line:max-line-length
              return  this.sanitizer.bypassSecurityTrustHtml(` <button onClick='popup("${row.requestID}")' style='background-color:rgb(247, 72, 117);font-weight: 500;line-height:1.25;padding: 0.55rem 0.5rem;font-size: 1.25rem;border-radius: 0.375rem;border: 2px solid transparent;vertical-align: middle;display: inline-block;text-shadow: none;font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;'>Donate</button> `);
            }
          },
          filter: false,
          sort: false
        },
        status: {
          title: 'Request Status',
          sort: true,
          filter: {
            type: 'list',
            defaultValue: 'Requested',
            config: {
              selectText: 'All',
              list:  [
                { value: 'Requested', title: 'Requested' },
                { value: 'Pending', title: 'Pending' },
                { value: 'Partly Fulfilled', title: 'Partly Fulfilled' },
                { value: 'Fulfilled', title: 'Fulfilled' },
                { value: 'Closed', title: 'Closed' },
              ],
            }
          },
        },
        hospitalName: {
          title: 'Requested By',
          sort: true
        },
        patientName: {
          title: 'Patient Name',
          sort: true
        },
        hospitalPhoneNo: {
          title: 'Contact No.',
          sort: true
        },
        // country: {
        //   title: 'Country'
        // },
        state: {
          title: 'State',
          sort: true
        },
        city: {
          title: 'City',
          sort: true
        },
        requiredItem: {
          title: 'Requested Item',
          sort: true
        },
        requiredQuantity: {
          title: 'Required Quantity',
          sort: true
        },
        requiredItemGrade: {
          title: 'Grade/ Certification',
          sort: true
        },
        requiredByDate: {
          title: 'Required By',
          type: date,
          sortDirection: 'desc',
          sort: true,
          valuePrepareFunction: (date) => {
            if (date) {
              return new DatePipe('en-US').transform(date, 'MM/dd/yyyy');
            }
            return null;
          },
        },
        verifiedDtTm: {
          title: 'Verified On',
          type: date,
          sort: true,
          valuePrepareFunction: (date) => {
            if (date) {
              return new DatePipe('en-US').transform(date, 'MM/dd/yyyy, h:mm:ss a');
            }
           return null;
          },
        },
        lastUpdatedDate: {
          title: 'Last Update',
          type: date,
          sort: true, 
          valuePrepareFunction: (date) => {
            if (date) {
              return new DatePipe('en-US').transform(date, 'MM/dd/yyyy, h:mm:ss a');
            }
            return null;
          },
        }
      }
    };
  // Requested -> Pledged -> Received
    public tableData = [ ];
    constructor(
        private zone: NgZone, public snackBar: MatSnackBar,
        private translate: TranslateService,
        private profileService: ProfileService, public dialog: MatDialog,
        private dataService: DataService, private router: Router,
        private sanitizer: DomSanitizer) {
         // this.user = this.profileService.setUpProfile();

        (window as any).popup = (reqId) => {
          const data = this.tableData.filter(i => i.requestID === reqId);
          this.viewDonorInformationPage(data[0]);
        };
      }
      translateMenuItems(): any {
        throw new Error('Method not implemented.');
      }
      navigateTo() {
        this.router.navigate(['/pages/donation']);
      }

      goToHome() {
        this.router.navigate(['/landing']);
      }

      toggleView() {
        if (this.view === 'table') {
          this.view = 'list';
        } else {
          this.view = 'table';
        }
      }

      // loadData() {
      //   this.loading = true;
      //   this.dataService.getAllHospitalRequests().subscribe((cases) => {
      //     this.tableData = cases;
      //     this.loading = false;
      //   }, err => {
      //     this.showSnackBar(err);
      //     this.loading = false;
      //     this.showSnackBar('Please refresh the page to see the changes');
      //   });
      // }

      loadTableData() {
        this.loading = true;
        this.dataService.getAllHospitalRequests().subscribe((cases) => {
          this.tableData = cases;
          this.loading = false;
        }, err => {
          this.showSnackBar(err);
          this.loading = false;
          this.showSnackBar('Please refresh the page to see the changes');
        });
      }

      ngOnInit(): void {
          this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
              this.translate.use(event.lang);
          });
          if (this.view === 'table') {
            this.loadTableData();
          }
      }

      ngOnDestroy() {
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
      }

      viewDonorInformationPage(rowData) {

        const donor = {} as IDonorInformation;
        donor.requestID = rowData.requestID;
        donor.requiredItem = rowData.requiredItem;
        donor.hospitalID = rowData.hospitalID;
        donor.hospitalEmail = rowData.hospitalEmail;
        donor.status = '';
        donor.dummy = rowData.pledgedQuantity;
        donor.requiredQuantity = rowData.requiredQuantity;
        const dialogRef = this.dialog.open(DonorInformationComponent, {
          data: donor,
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
           this.loading = true;
           setTimeout(() => {
            this.loadData();
           }, 5000);
          }
        });
      }

      showSnackBar(msg) {
      this.zone.run(() => {
        this.snackBar.open(msg, '', { duration: 3000 });
      });
    }

    async loadListData() {
      this.tableData = [];
      try {
        this.loading = true;
        this.tableData = await this.dataService.getAllHospitalRequests().toPromise();
      } finally {
        this.loading = false;
      }
    }

    async loadNext() {
      if (this.loading) { return; }
      this.loading = true;
      if (this.tableData.length === 0) {
        this.tableData = await this.dataService.getAllHospitalRequests().toPromise();
      }
      this.placeholders = new Array(this.pageSize);
      const items = [];
      const startIndex = this.pageToLoadNext * this.pageSize;
      for (let i = startIndex; i <= this.pageSize; i++) {
        if (i >= this.tableData.length) {
          break;
        }
        items.push(this.tableData[i]);
      }
      this.placeholders = [];
      this.items = this.items.concat(items);
      this.loading = false;
      this.pageToLoadNext++;
    }

    loadData() {
      this.view === 'list' ? this.loadListData() : this.loadTableData();
    }

    getStatusColor(status: string) {
      switch (status) {
        case 'Closed':
          return 'primary';
        case 'Fulfilled':
          return 'success';
        case 'Partially Fulfilled':
          return 'warning';
        default:
          return 'danger';
      }
    }

    aboutHospital(hospitalId: string) {
      const navigationExtras: NavigationExtras = {
        skipLocationChange: true,
        queryParams: {
          id: hospitalId
        }
      };
      this.router.navigate(['/hospitalProfile'], navigationExtras );
    }

    getLocalDate(date) {
      const d = new Date(date);
      return d.toLocaleDateString();
    }

    createHospitalRequest() {
      // this.loading = true;
      // this.dataService.getHospitalRecord(this.profileService.getGroupId()).subscribe((hospitalRecord: IHospital) => {
        const data: IHospitalRequest = {
          status: 'New',
          requestID: this.generateRequestID(),
          hospitalID: 'c660d8bc-20a0-4190-9327-53f072966684',
          hospitalName: '',
          hospitalPhoneNo: '',
          hospitalEmail: '',
          country: 'INDIA',
          state: '',
          city: '',
          patientName: '',
          careHospital: '',
          verifiedDtTm: '',
          notes: '',
          requiredItem: '',
          requiredQuantity: '',
          requiredItemGrade: '',
          pledgedQuantity: '',
          receivedQuantity: '',
          requiredByDate: '',
          pledgedBy: '',
          pledgedByEmail: '',
          pledgedByPhoneNo: '',
          createdOn: new Date().toISOString().split('T')[0] ,
          createdByName: this.user.fullName,
          createdByEmail: this.user.email,
          lastUpdatedDate: new Date().toISOString().split('T')[0] ,
          lastUpdatedByName: this.user.fullName,
          lastUpdatedByEmail: this.user.email
        };
       // this.loading = false;

        const dialogRef = this.dialog.open(AddRequestsComponent, {
          data: data
        });

        dialogRef.afterClosed().subscribe(newRequest => {
          if (newRequest) {
              this.loading = true;
             setTimeout(() => {
              this.loadData();
             }, 5000);
            }
          });
        //   this.loading = false;
        // });
        this.loading = false;
      }

      generateRequestID() {
        let dt = new Date().getTime();
        const uuid = 'xxxxxxxx'.replace(/[xy]/g, function(c) {
            // tslint:disable-next-line:no-bitwise
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            // tslint:disable-next-line:no-bitwise
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
      }

}
