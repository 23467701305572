import {Component} from '@angular/core';
import { IContactInformation } from './IContactInformation';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'gjs-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactUsComponent {

  constructor(private http: HttpClient, public snackBar: MatSnackBar) {

  }

  public information = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    phoneNum: new FormControl(),
    email: new FormControl('', Validators.required),
    hospitalName: new FormControl('', Validators.required),
    hospitalAddress: new FormControl('', Validators.required),
    hospitalPhoneNum: new FormControl(),
    hospitalEmail: new FormControl(),
    registrationType: new FormControl('', Validators.required)
  });

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');
  private options = { headers: this.headers };
  public done = false;

  public sendEmail() {
    const sendEmail = ((<any>window)._env_ && (<any>window)._env_.CONTACTUS_EMAIL) ||
     'https://prod-17.westus2.logic.azure.com:443/workflows/1c1e4fea0fa04ae4a96c20e00435c28c/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=VmpFqkQFQmCWysn7x0zO4To9dpOL2LTK8I2LO7GrSyY';
    if (!sendEmail) {
      const msg = 'No EndPoint for email';
      this.snackBar.open(msg, '', { duration: 3000 });
    }
    console.log(this.information.getRawValue());
    const body = JSON.stringify(this.information.getRawValue());
    this.http.post(`${sendEmail}`, body, this.options).subscribe((w: any) => {
      const msg = 'Successfully send email.';
      this.done = true;
    }, err => {
      console.log(JSON.stringify(err.message));
      this.snackBar.open('Failed. Please try again later.', '', { duration: 3000 });
    });
  }
}

