import { Component, OnInit, Inject, AfterViewInit, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar, MatTabChangeEvent, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../services/data.service';
import { AppGlobal } from '../../../common/app.global';
import { IDonorInformation } from '../../../common/models/IDonorInformation';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material';
import * as RequestClient from 'request';
import { ProfileService } from '../../../services/profile.service';
import { IUser } from '../../../common/models/IUser';
import { NbToastrService } from '@nebular/theme';
@Component({
  selector: 'gjs-donorinformation',
  templateUrl: './donorInformation.component.html',
  styleUrls: ['./donorInformation.component.scss'],
  providers: [DataService, {
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})

export class DonorInformationComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper: MatStepper;
  public loading = false;
  public checked = false;
  private user = <IUser>{};
  public isReadOnly = false;

  constructor(
    private toastrService: NbToastrService,
    private zone: NgZone, public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DonorInformationComponent>, public dialog: MatDialog,
    private http: HttpClient, private dataService: DataService,
    private config: AppGlobal, private cdr: ChangeDetectorRef, private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: IDonorInformation) {
      if (data.status === '') {
        data.pledgedItem = data.requiredItem;
      } else {
        this.isReadOnly = true;
      }
  }

  async ngOnInit() {
  }

  ngAfterViewInit() {
  }

  onCancel(): void {
    this.zone.run(() => {
      this.dialogRef.close();
    });
  }

  onChange($event) {
    this.checked = !this.checked;
  }

  showSnackBar(msg) {
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

  registerDonation(data) {
    const appData =  {
      'donorComments': data.donorComments || '',
      'donorEmail': data.donorEmail || '',
      'donorFirstName': data.donorFirstName || '',
      'donorID': data.donorID || '',
      'donorLastName':  data.donorLastName || '',
      'donorOrganization':  data.donorOrganization || '',
      'donorPhone': data.donorPhone || '',
      'hospitalEmail': data.hospitalEmail || '',
      'hospitalID': data.hospitalID || '',
      'pledgedQuantity': data.pledgedQuantity || '',
      'requiredItem': data.requiredItem || '',
      'requestID': data.requestID || '',
      'dummy': '',
      'requiredQuantity': '',
      // 'pledgeID': 'P' + Math.floor(100000 + Math.random() * 900000).toString()
      'pledgeID': this.generatePledgeID()
    } as IDonorInformation;
    this.data = appData;
    console.log('Donor Information Sent:\n' + JSON.stringify(appData));
    this.dataService.registerDonation(this.data).subscribe((donations) => {
    this.showToast();
    this.callDonorPledgeEmailFlow(this.data);
    this.showSnackBar('Thank you for your donation !!');
    console.log('Donation Data Received:\n' + JSON.stringify(donations));
    this.dialogRef.close();
    }, err => {
      console.log(err.error.message);
      this.showSnackBar('Please Submit your donation again.');
    });
  }

  updateDonation(data) {
    this.user = this.profileService.setUpProfile();
    const appData =  {
      'requestID': data.requestID || '',
      'pledgeID': data.pledgeID || '',
      'pledgedQuantity': data.pledgedQuantity || '',
      'donatedQuantity': data.donatedQuantity || '',
      'lastUpdatedByName': this.user.fullName || '',
      'pledgedItem': data.pledgedItem || '',
      'donatedBy' : data.donorFirstName || '' || data.donorLastName
    };

    console.log('Donor Information Sent:\n' + JSON.stringify(appData));
    this.dataService.updateDonation(appData).subscribe((donor) => {
      this.showSnackBar('Donor Information updated successfully');
          console.log('Donor Information Received:\n' + JSON.stringify(donor));
          this.dialogRef.close();
    }, err => {
      console.log(err.error.message);
      this.showSnackBar('Please try again ' + err.error.message);
    });
  }

  generatePledgeID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx'.replace(/[xy]/g, function(c) {
        // tslint:disable-next-line:no-bitwise
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        // tslint:disable-next-line:no-bitwise
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  public callDonorPledgeEmailFlow(data: IDonorInformation) {

    const flowData =  {
      'requestID': data.requestID,
      'donorComments': data.donorComments,
      'donorEmail': data.donorEmail,
      'donorFirstName': data.donorFirstName,
      'donorID': data.donorID,
      'pledgeID': data.pledgeID,
      'donorLastName':  data.donorLastName,
      'donorOrganization':  data.donorOrganization,
      'donorPhone': data.donorPhone,
      'entity': 'Donor',
      'hospitalEmail': data.hospitalEmail,
      'hospitalID': data.hospitalID,
      'quantity': data.pledgedQuantity,
      'requiredItem': data.requiredItem
    };

    RequestClient( {
      url: this.config.sendEmailFlow,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: flowData,
      json: true
    }, (err, xres, body) => {
      if (err ) {
        console.log('err' + err);
      } else if ( xres && xres.statusCode !== 200) {
        console.log('xres' + JSON.stringify(xres));
      } else {
        console.log(body);
      }
    });
  }

  showToast() {
    this.toastrService.success(
     'We will notify you when your donation reaches the hospital.',
      `Thank you for your donation !!`,
      {
        duration: 9000,
      }
    );

}

}
