import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatSnackBarModule, MatAutocompleteModule,
  MatStepperModule, MatSelectModule,  MatButtonModule, MatButtonToggleModule } from '@angular/material';

import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { LandingComponent } from './components/landing/landing.component';

import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbListModule,
  NbBadgeModule,
  NbToastrModule
} from '@nebular/theme';

import {
  FooterComponent,
  HeaderComponent,
} from './components';
import { CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, SafeHtmlPipe } from './pipes';
import {
  BaseLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/pageNotFound/pageNotFound.component';
import { DonationHomeComponent } from './components/donationHome/donationHome.component';

import { LogoutComponent } from './components/logout/logout.component';
import { SharedModule} from '../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { DonorInformationComponent } from './components/donorInformation/donorInformation.component';
import { ContactUsComponent } from './components/contactus/contactus.component';
import { HospitalProfileComponent } from './components/hospitalProfile/hospitalProfile.component';
import { AddRequestsComponent }  from './components/addRequests/addRequests.component';

// tslint:disable-next-line:max-line-length
const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, MatCheckboxModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatSnackBarModule, 
  MatStepperModule, MatSelectModule, MatButtonModule, MatButtonToggleModule, MatAutocompleteModule];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NgbModule,
  NbButtonModule,
  NbSelectModule,
  NbListModule,
  NbBadgeModule,
  NbToastrModule
];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  BaseLayoutComponent,
  LandingComponent,
  PageNotFoundComponent,
  DonationHomeComponent,
  DonorInformationComponent,
  LogoutComponent,
  ContactUsComponent,
  HospitalProfileComponent,
  AddRequestsComponent
];


const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  SafeHtmlPipe,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'default',
    },
    [ DEFAULT_THEME, COSMIC_THEME ],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbToastrModule.forRoot().providers
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, RouterModule, SharedModule,
    MatIconModule, Ng2SmartTableModule, MatProgressSpinnerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES, DonorInformationComponent, AddRequestsComponent],
  entryComponents: [DonorInformationComponent, AddRequestsComponent ],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
