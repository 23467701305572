import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IUser } from '../../../../common/models/IUser';
import { IHospital } from '../../../../services/IHospital';
import { ProfileService } from '../../../../services/profile.service';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatDialog } from '@angular/material';
import { DataService } from '../../../../services/data.service';
import { DatePipe } from '@angular/common';
import { ProductDetailsComponent } from '../productDetails/productDetails.component';
// tslint:disable-next-line:max-line-length
import { HospitalMessageDetailsComponent } from '../../hospitalMessages/hospitalMessageDetails/hospitalMessageDetails.component';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
    selector: 'gjs-products-home',
    templateUrl: './productsHome.component.html',
    styleUrls: ['./productsHome.component.scss'],
    providers: [DataService]
})

export class ProductsHomeComponent implements OnInit, OnDestroy {

  public error: any = undefined;
  public browserLang: string;
  private user = <IUser>{};
  private subscription: Subscription;
  public data;
  public loading = false;
  hospital = <IHospital>{};

  public tableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'sendMessage',
          type: 'html',
          filter: false,
          title: '<i class="material-icons text-primary" title="Click here to send a message to the supplier">mail_outline</i>'
        }
      ],
      position: 'right',
    },
    pager : {
      display : true,
      perPage : 5
    },
    columns: {
      supplierName: {
        title: 'Supplier Name'
      },
      // productID: {
      //   title: 'Product #'
      // },
      productName: {
        title: 'Product Name'
      },
      productCode: {
        title: 'Product Code'
      },
      productGrade: {
        title: 'Product Grade'
      },
      onHandQuantity: {
        title: 'On Hand Qty'
      },
      productionEstimatedDate: {
        title: 'Production Estimated Date',
        type: 'date',
        valuePrepareFunction: (date) => {
          if (date) {
            return new DatePipe('en-US').transform(date, 'MM-dd-yyyy');
          }
            return null;
        },
      },
      isFDAApproved: {
        title: 'Is FDA Approved'
      }
    }
  };

  public tableData = [ ];

  onCustomAction(event) {
    switch ( event.action) {
      case 'sendMessage':
        this.sendMessage(event);
        break;
    }
  }

  sendMessage(event) {
    const messageData = event.data;
    messageData.hospitalID = this.hospital.hospitalID;
    messageData.hospitalName = this.hospital.hospitalName;
    messageData.hospitalEmail = this.hospital.hospitalEmail;
    messageData.status = 'New';
    messageData.date = new Date().toLocaleString();
    messageData.messageID = this.genarateID();
    const dialogRef = this.dialog.open(HospitalMessageDetailsComponent, {
     width: '50%',
     // height: '100%',
     // position: { right: '0', top: '0', bottom: '0'},
      data: {...messageData},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
       this.loading = true;
       setTimeout(() => {
        this.loadData();
       }, 5000);
      }
    });
  }

  constructor(
      private zone: NgZone, public snackBar: MatSnackBar,
      private translate: TranslateService,
      private profileService: ProfileService, public dialog: MatDialog,
      private dataService: DataService, private sanitizer: DomSanitizer) {
        this.user = this.profileService.setUpProfile();
    }

    translateMenuItems(): any {
      throw new Error('Method not implemented.');
    }

    loadData() {
     this.loading = true;
      this.dataService.getAllProductsRecords().subscribe((products) => {
       // console.log(products);
        this.tableData = products;
        this.loading = false;
      }, err => {
        this.showSnackBar(err.error.message);
        this.loading = false;
        this.showSnackBar('Please refresh the page to see the changes');
      });
      this.loading = true;
      this.dataService.getHospitalRecord(encodeURIComponent(this.profileService.getGroupId()))
      .subscribe((hospitalData) => {
        console.log('Hospital ID: ' + hospitalData.hospitalID );
        this.hospital = hospitalData;
        this.loading = false;
         }, err => {
        this.showSnackBar(err.message);
        this.loading = false;
      });

    }


    ngOnInit(): void {
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.use(event.lang);
      });
      this.loadData();
    }

    ngOnDestroy() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }

    viewProductDetails(event) {
      const dialogRef = this.dialog.open(ProductDetailsComponent, {
        height: '100%',
        position: { right: '0', top: '0', bottom: '0'},
        data: {...event.data},
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
         this.loading = true;
         setTimeout(() => {
          this.loadData();
         }, 5000);
        }
      });
    }

    showSnackBar(msg) {
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

  genarateID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx'.replace(/[xy]/g, function(c) {
      // tslint:disable-next-line:no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
}
