import { ProfileService } from './profile.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICase } from './ICase';
import { IHospital } from './IHospital';
import { ISupplier } from './ISupplier';
import { IProduct } from './IProduct';
import { IMessage } from './IMessage';
import { IHospitalRequest } from './IHospitalRequest';
import { Observable } from 'rxjs';
import { IDonorInformation } from '../common/models/IDonorInformation';



@Injectable()
export class DataService {

    private apiUrl = ((<any>window)._env_ && (<any>window)._env_.API_URL) || 'http://localhost/api';
    private openApiUrl = ((<any>window)._env_ && (<any>window)._env_.OPEN_API_URL) || 'http://localhost/_api';
    private headers = new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json');
    private options = { headers: this.headers };
    constructor(private http: HttpClient) {
    }

    public getCases(): Observable<ICase[]> {
        return new Observable<ICase[]>((obsr) => {
            this.http.get(`${this.apiUrl}/cases`, this.options).subscribe((w: any) => {
                if (w === null) {
                    obsr.next([]);
                } else {
                    obsr.next(w);
                }
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public getCaseHistory(id: string): Observable<ICase[]> {
        return new Observable<ICase[]>((obsr) => {
            this.http.get(`${this.apiUrl}/history/${id}`, this.options).subscribe((w: any) => {
                if (w === null) {
                    obsr.next([]);
                } else {
                    obsr.next(w);
                }
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public getCase(id: string): Observable<ICase> {
        return new Observable<ICase>((obsr) => {
            this.http.get(`${this.apiUrl}/case/${id}`, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public registerCase(caseRecord: any): Observable<any> {
        return new Observable<any>((obsr) => {
            const body = JSON.stringify(caseRecord);
            this.http.post(`${this.apiUrl}/case`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    // tslint:disable-next-line:max-line-length
    public updateStatus(patientID: string, status: string, reportedBy: string, comments: string, isBloodPlasmaVolunteer: string): Observable<ICase> {
        return new Observable<ICase>((obsr) => {
            const body = JSON.stringify({
                'patientID': patientID,
                'status': status,
                'reportedBy': reportedBy,
                'comments': comments,
                'isBloodPlasmaVolunteer': isBloodPlasmaVolunteer,
            });
            this.http.post(`${this.apiUrl}/case/${patientID}/updateStatus`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    // Hospital

    public getHospitals(): Observable<IHospital[]> {
        return new Observable<IHospital[]>((obsr) => {
            this.http.get(`${this.apiUrl}/hospitals`, this.options).subscribe((w: any) => {
                if (w === null) {
                    obsr.next([]);
                } else {
                    obsr.next(w);
                }
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public getHospRecordHistory(id: string): Observable<IHospital[]> {
        return new Observable<IHospital[]>((obsr) => {
            this.http.get(`${this.apiUrl}/hospital/history/${id}`, this.options).subscribe((w: any) => {
                if (w === null) {
                    obsr.next([]);
                } else {
                    obsr.next(w);
                }
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public getHospitalRecord(id: string): Observable<IHospital> {
        return new Observable<IHospital>((obsr) => {
            this.http.get(`${this.apiUrl}/hospital/${id}`, this.options).subscribe((w: any) => {
                const hospitalData: IHospital = w;
                // hospitalData.hospitalEmail = w.createdByEmail;
                obsr.next(hospitalData);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public registerHospital(hospitalRecord: any): Observable<any> {
        return new Observable<any>((obsr) => {
            const body = JSON.stringify(hospitalRecord);
            this.http.post(`${this.apiUrl}/hospital`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public updateHospital(hospitalRecord: any): Observable<any> {
        return new Observable<IHospital>((obsr) => {
            const body = JSON.stringify(hospitalRecord);
            // tslint:disable-next-line:max-line-length
            this.http.post(`${this.apiUrl}/hospital/${hospitalRecord.hospitalID}/updateHospital`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    // Supplier

    public getSuppliers(): Observable<ISupplier[]> {
        return new Observable<ISupplier[]>((obsr) => {
            this.http.get(`${this.apiUrl}/suppliers`, this.options).subscribe((w: any) => {
                if (w === null) {
                    obsr.next([]);
                } else {
                    obsr.next(w);
                }
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public getSuppRecordHistory(id: string): Observable<ISupplier[]> {
        return new Observable<ISupplier[]>((obsr) => {
            this.http.get(`${this.apiUrl}/supplier/history/${id}`, this.options).subscribe((w: any) => {
                if (w === null) {
                    obsr.next([]);
                } else {
                    obsr.next(w);
                }
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public getSupplierRecord(id: string): Observable<ISupplier> {
        return new Observable<ISupplier>((obsr) => {
            this.http.get(`${this.apiUrl}/supplier/${id}`, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public registerSupplier(supplierRecord: any): Observable<any> {
        return new Observable<any>((obsr) => {
            const body = JSON.stringify(supplierRecord);
            this.http.post(`${this.apiUrl}/supplier/addSupplier`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public updateSupplier(supplierRecord: any): Observable<any> {
        return new Observable<ISupplier>((obsr) => {
            const body = JSON.stringify(supplierRecord);
            // tslint:disable-next-line:max-line-length
            this.http.post(`${this.apiUrl}/supplier/${supplierRecord.supplierID}/updateSupplier`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    // Hospital Requests

    public getAllHospitalRequests(): Observable<IHospitalRequest[]> {
        return new Observable<IHospitalRequest[]>((obsr) => {
            this.http.get(`${this.openApiUrl}/hospitalrequests`, this.options).subscribe((w: any) => {
                if (w === null) {
                    obsr.next([]);
                } else {
                    obsr.next(w);
                }
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public getHospitalRequests(id): Observable<IHospitalRequest[]> {
        return new Observable<IHospitalRequest[]>((obsr) => {
            this.http.get(`${this.openApiUrl}/hospitalrequests/${id}`, this.options).subscribe((w: any) => {
                if (w === null) {
                    obsr.next([]);
                } else {
                    obsr.next(w);
                }
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public registerHospitalRequest_open(requestRecord: any): Observable<any> {
        return new Observable<any>((obsr) => {
            const body = JSON.stringify(requestRecord);
            this.http.post(`${this.openApiUrl}/hospitalRequests`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }


    public registerHospitalRequest(requestRecord: any): Observable<any> {
        return new Observable<any>((obsr) => {
            const body = JSON.stringify(requestRecord);
            this.http.post(`${this.apiUrl}/hospitalRequests`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public updateRequest(requestRecord: any): Observable<any> {
        return new Observable<IHospitalRequest>((obsr) => {
            const body = JSON.stringify(requestRecord);
            // tslint:disable-next-line:max-line-length
            this.http.post(`${this.apiUrl}/hospitalRequests/${requestRecord.requestID}`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public registerDonation(requestRecord: any): Observable<any> {
        return new Observable<IDonorInformation>((obsr) => {
            const body = JSON.stringify(requestRecord);
            // tslint:disable-next-line:max-line-length
            this.http.post(`${this.openApiUrl}/pledges`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public updateDonation(requestRecord: any): Observable<any> {
        return new Observable<IDonorInformation>((obsr) => {
            const body = JSON.stringify(requestRecord);
            // tslint:disable-next-line:max-line-length
            this.http.post(`${this.apiUrl}/pledges/${requestRecord.requestId}`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public getHospitalRequestDonors(requestId: string): Observable<IDonorInformation[]> {
        return new Observable<IDonorInformation[]>((obsr) => {
            this.http.get(`${this.apiUrl}/pledges/${requestId}`, this.options).subscribe((w: any) => {
                if (w === null) {
                    obsr.next([]);
                } else {
                    obsr.next(w);
                }
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public closeRequest(requestRecord: any): Observable<any> {
        return new Observable<IHospitalRequest>((obsr) => {
            const body = JSON.stringify(requestRecord);
            // tslint:disable-next-line:max-line-length
            this.http.post(`${this.apiUrl}/hospitalRequests/${requestRecord.requestID}/close`, body, this.options).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public deleteRequest(requestId: string): Observable<any> {
        return new Observable<IHospitalRequest>((obsr) => {
            // tslint:disable-next-line:max-line-length
            this.http.delete(`${this.apiUrl}/hospitalRequests/${requestId}/delete`).subscribe((w: any) => {
                obsr.next(w);
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

    public getRequestHistory(requestID): Observable<IHospitalRequest[]> {
        return new Observable<IHospitalRequest[]>((obsr) => {
            this.http.get(`${this.apiUrl}/hospitalrequests/${requestID}/history`, this.options).subscribe((w: any) => {
                if (w === null) {
                    obsr.next([]);
                } else {
                    obsr.next(w);
                }
                obsr.complete();
            }, err => {
                obsr.error(err);
                obsr.complete();
            });
        });
    }

        // Products Start
        public getAllProductsRecords(): Observable<IProduct[]> {
            return new Observable<IProduct[]>((obsr) => {
                this.http.get(`${this.apiUrl}/products`, this.options).subscribe((w: any) => {
                    if (w === null) {
                        obsr.next([]);
                    } else {
                        obsr.next(w);
                    }
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }

        public getSupplierProducts(id): Observable<IProduct[]> {
            return new Observable<IProduct[]>((obsr) => {
                this.http.get(`${this.apiUrl}/product/${id}`, this.options).subscribe((w: any) => {
                    if (w === null) {
                        obsr.next([]);
                    } else {
                        obsr.next(w);
                    }
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }

        public registerProduct(productRecord: any): Observable<any> {
            return new Observable<any>((obsr) => {
                const body = JSON.stringify(productRecord);
                this.http.post(`${this.apiUrl}/product`, body, this.options).subscribe((w: any) => {
                    obsr.next(w);
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }

        public updateProduct(productRecord: any): Observable<any> {
            return new Observable<IProduct>((obsr) => {
                const body = JSON.stringify(productRecord);
                // tslint:disable-next-line:max-line-length
                this.http.post(`${this.apiUrl}/product/${productRecord.productID}/updateProduct`, body, this.options).subscribe((w: any) => {
                    obsr.next(w);
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }
        // Products End

        // Messages Start
        public createMessage(messageRec: any): Observable<any> {
            return new Observable<any>((obsr) => {
                const body = JSON.stringify(messageRec);
                this.http.post(`${this.apiUrl}/message`, body, this.options).subscribe((w: any) => {
                    obsr.next(w);
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }

        public replyMessage(record: any): Observable<any> {
            return new Observable<IMessage>((obsr) => {
                const body = JSON.stringify(record);
                // tslint:disable-next-line:max-line-length
                this.http.post(`${this.apiUrl}/replyMessage`, body, this.options).subscribe((w: any) => {
                    obsr.next(w);
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }

        public getAllMessages(): Observable<IMessage[]> {
            return new Observable<IMessage[]>((obsr) => {
                this.http.get(`${this.apiUrl}/messages`, this.options).subscribe((w: any) => {
                    if (w === null) {
                        obsr.next([]);
                    } else {
                        obsr.next(w);
                    }
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }

        public getSupplierMessages(supplierID): Observable<IMessage[]> {
            return new Observable<IMessage[]>((obsr) => {
                this.http.get(`${this.apiUrl}/messages/supplier/${supplierID}`, this.options).subscribe((w: any) => {
                    if (w === null) {
                        obsr.next([]);
                    } else {
                        obsr.next(w);
                    }
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }

        public getHospitalMessages(hospitalID): Observable<IMessage[]> {
            return new Observable<IMessage[]>((obsr) => {
                this.http.get(`${this.apiUrl}/messages/hospital/${hospitalID}`, this.options).subscribe((w: any) => {
                    if (w === null) {
                        obsr.next([]);
                    } else {
                        obsr.next(w);
                    }
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }

        public getHospitalNewMessages(hospitalID): Observable<IMessage[]> {
            return new Observable<IMessage[]>((obsr) => {
                const date1 = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleString();
                const date2 = new Date().toLocaleString();
                const body = {date1, date2};
                // tslint:disable-next-line:max-line-length
                this.http.post(`${this.apiUrl}/messages/hospital/newMsgs/${hospitalID}`, body, this.options).subscribe((w: any) => {
                    if (w === null) {
                        obsr.next([]);
                    } else {
                        obsr.next(w);
                    }
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }

        public getSupplierNewMessages(supplierID): Observable<IMessage[]> {
            return new Observable<IMessage[]>((obsr) => {
                const date1 = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleString();
                const date2 = new Date().toLocaleString();
                const body = {date1, date2};
                // tslint:disable-next-line:max-line-length
                this.http.post(`${this.apiUrl}/messages/supplier/newMsgs/${supplierID}`, body, this.options).subscribe((w: any) => {
                    if (w === null) {
                        obsr.next([]);
                    } else {
                        obsr.next(w);
                    }
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }
        // Messages End

        // Donor
        getDonor(pledgeId: string) {
            return new Observable<IDonorInformation>((obsr) => {
                // tslint:disable-next-line:max-line-length
                this.http.get(`${this.apiUrl}/pledge/${pledgeId}`, this.options).subscribe((w: IDonorInformation) => {
                    obsr.next(w);
                    obsr.complete();
                }, err => {
                    obsr.error(err);
                    obsr.complete();
                });
            });
        }
}
