import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileItem } from 'ng2-file-upload';

@Injectable()
export class UploadService {

    private options = null;
    private blobUri =  'https://willsbcdata.blob.core.windows.net';
    private container = 'fileupload';
    // tslint:disable-next-line:max-line-length
    private blobConnString = 'DefaultEndpointsProtocol=https;AccountName=willsbcdata;AccountKey=Dt/7NBx5aZ01OHpYmCMoLFFEy/vHyMNwjh2NUFxN7zx45C36OkL+bke6GW0KFSdMYmBz7S+dx9xt0h5pZ7xQzA==;EndpointSuffix=core.windows.net';
    // generate container level access token using snapshot
    // tslint:disable-next-line:max-line-length
    private token = '?sp=racwdl&st=2019-12-02T12:23:35Z&se=2020-01-31T12:23:00Z&sv=2019-02-02&sr=c&sig=3HxiJuKPtUeaplDxcBsXypDf4b%2BzpeclgHxOISPyTrE%3D';
    // tslint:disable-next-line:max-line-length
    // private token = 'sv=2018-03-28&ss=bfqt&srt=sco&sp=rwdlacup&se=2029-02-11T05:00:00Z&st=2019-02-12T05:00:00Z&spr=https&sig=iKtDn%2FjqB69DBHrKgsNZea4nL%2Bk9y4%2BzGK8s7gTreUs%3D';
    // tslint:disable-next-line:max-line-length
    // private token = 'sv=2018-03-28&ss=bfqt&srt=sco&sp=rwdlacup&se=2029-02-12T15:04:26Z&st=2019-02-12T07:04:26Z&spr=https&sig=IxQgHbwQVpnxPHqZkAod9uohmF1AnicZHWjLy0tKcN4%3D';
    constructor (private http: HttpClient) {
        const headers = new HttpHeaders()
                        .set('Content-Type', 'application/json');
        this.options = { headers: headers };
    }

    public get BlobUri(): string {
            return this.blobUri;
    }

    getBlobService() {
        const blobService = (<any>window).AzureStorage.Blob.createBlobServiceWithSas(this.blobUri, this.token)
                .withFilter(new (<any>window).AzureStorage.Blob.ExponentialRetryPolicyFilter());
                blobService.createContainerIfNotExists(this.container, (error, result) => {
                    if (error) {
                        console.log('createContainerIfNotExists failed.');
                    } else {
                        console.log('createContainerIfNotExists successful.');
                    }
                });
        return blobService;
    }

    uploadFile(item: FileItem): Observable<any> {
        return new Observable(observer => {
                const blobService = this.getBlobService();
                const blobName = item.formData.blobName;
                const customBlockSize = item.file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
                blobService.singleBlobPutThresholdInBytes = customBlockSize;
                const speedSummary = blobService.createBlockBlobFromBrowserFile(this.container,
                    blobName, item._file, {blockSize : customBlockSize},
                    (error, result, response) => {
                        if (error) {
                            console.log('error:' + error);
                            console.log('result:' + result);
                            console.log('response:' + response);
                        } else {
                            console.log('Uploaded ' + blobName);
                            observer.next({blobName: blobName,
                                blobUrl: this.getFileUrl(blobService, blobName)});
                            observer.complete();
                        }
                });
                speedSummary.on('progress', function () {
                    const process = speedSummary.getCompletePercent();
                    console.log('process' + process);
                });

        });
    }

    getFileUrl(blobService: any, blob: string): string {
        let url = blobService.getUrl(this.container, blob, this.token) as string;
        url = url.replace('?%3F', '?');
        return url;
    }

    public getRandomFileName(fileName: string): string {
        const fName = this.getFileNameWithoutExtension(fileName);
        const ext = this.getExtension(fileName);
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let name = '';
        for (let i = 0; i < 16; i++) {
            name += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return !fileName ? name : `${fName}_${name}${ext}`;
    }

    public getExtension(filename: string) {
         const index = filename.lastIndexOf('.');
         return index === -1 ? '' : filename.slice(index);
    }

    private getFileNameWithoutExtension(filename: string) {
        const index = filename.lastIndexOf('.');
        return index === -1 ? filename : filename.slice(0, index);
    }
}
