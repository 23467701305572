import {Component} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'gjs-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {

  constructor(private router: Router) {
  }

  public navigateTo() {

    const navigationExtras: NavigationExtras = {
      skipLocationChange: true
    };

    this.router.navigate(['/pages', 'home'], navigationExtras);
  }

  public navigateToSupplier() {
    const navigationExtras: NavigationExtras = {
      skipLocationChange: true
    };

    this.router.navigate(['/pages', 'supplier'], navigationExtras);
  }

  public navigateToDonor() {

    const navigationExtras: NavigationExtras = {
      skipLocationChange: true
    };

    this.router.navigate(['donate'], navigationExtras);
  }

  public navigateToCases() {

    const navigationExtras: NavigationExtras = {
      skipLocationChange: true
    };

    this.router.navigate(['/pages', 'case'], navigationExtras);
  }

  public navigateToHospital() {

    const navigationExtras: NavigationExtras = {
      skipLocationChange: true
    };

    this.router.navigate(['/pages', 'hospital'], navigationExtras);
  }

  public navigateToContactUs() {
    const navigationExtras: NavigationExtras = {
      skipLocationChange: true
    };

    this.router.navigate(['contactus'], navigationExtras);
  }
}

