import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'maskDate' })
export class MaskDatePipe implements PipeTransform {
    transform(value: Date): String {
        if ( !value) {
          return ;
        }
        return 'XX/XX/' + value.getFullYear();
      }
}
