
export class ICase {
    public patientID: string;
    // public patientName: string;
    public patientDOB: string;
    public gender: string;
    public age: string;
    public reportedBy: string;
    public reportedDate: string;
    public confirmedDate: string;
    public activeDate: string;
    public recoveredOnDate: string;
    public dateOfVaccination: string;
    public dateOfDeath: string;
    public status: string;
    public contactType: string;
    public labConfirmationBy: string;
    public caseNumber: string;
    public caseStatus: string;
    public country: string;
    public state: string;
    public isBloodPlasmaVolunteer: string;
    public comments: string;
    public createdOn: string;
    public createdByName: string;
    public lastUpdatedDate: string;
    public lastUpdatedByName: string;

}
