import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages-routing.module';
import { ThemeModule } from '../@theme/theme.module';
import { HomeComponent } from './home/home.component';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import { AuthGuard } from '../auth.guard';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { MatDialogModule, MatFormFieldModule, MatInputModule, MatSnackBarModule,
  MatStepperModule, MatSelectModule, MatTabsModule } from '@angular/material';
import { AddCaseComponent } from './case/addCase/addCase.component';
import { CaseHomeComponent } from './case/caseHome/caseHome.component';
import { DocViewerComponent } from './viewer/docViewerComponent';
import { BlockViewerComponent} from './viewer/blockViewerComponent';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { FileUploadModule  } from 'ng2-file-upload';
import { UploadService } from '../services/uploadService';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import {SharedModule} from '../shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '../token.interceptor';
import { AddHospitalComponent } from './admin/addHospital/addHospital.component';
import { AddSupplierComponent } from './admin/addSupplier/addSupplier.component';
import { AdminHomeComponent } from './admin/adminHome/adminHome.component';
import { HospitalDetailsComponent } from './hospital/hospitalDetails/hospitalDetails.component';
import { HospitalHomeComponent } from './hospital/hospitalHome/hospitalHome.component';
import { AddRequestsComponent } from './hospital/hospitalRequests/addRequests/addRequests.component';
import { RequestsHomeComponent } from './hospital/hospitalRequests/requestsHome/requestsHome.component';
import { ProductDetailsComponent } from './hospital/supplierProducts/productDetails/productDetails.component';
import { ProductsHomeComponent } from './hospital/supplierProducts/productsHome/productsHome.component';
import { SupplierHomeComponent } from './supplier/supplierHome/supplierHome.component';
import { SupplierDetailsComponent } from './supplier/supplierDetails/supplierDetails.component';
import { AddProductComponent } from './supplier/supplierProducts/addProducts/addProduct.component';
import { ProductsHomeComponent as SupplierProductsHomeComponent } from './supplier/supplierProducts/productsHome/productsHome.component';
import { HospitalRequestsComponent as SupplierRequestsHomeComponent } from './supplier/hospitalRequests/hospitalRequests.component';
import { HospitalDetailsComponent as SupplierHospitalDetailsComponent } from './supplier/hospitalDetails/hospitalDetails.component';
import { SupplierMessagesHomeComponent } from './supplier/supplierMessages/supplierMessagesHome/suppMsgsHome.component';
import { SupplierMessageDetailsComponent } from './supplier/supplierMessages/supplierMessageDetails/supplierMessageDetails.component';
import { HospitalMessagesHomeComponent } from './hospital/hospitalMessages/hospitalMessagesHome/hospitalMsgsHome.component';
import { HospitalMessageDetailsComponent } from './hospital/hospitalMessages/hospitalMessageDetails/hospitalMessageDetails.component';

import { DonorSearchComponent } from './hospital/donorSearch/donor.search';

const PAGES_COMPONENTS = [
  PagesComponent,
  DocViewerComponent,
  BlockViewerComponent,
  HomeComponent,
  CaseHomeComponent,
  AddCaseComponent,
  AddHospitalComponent,
  AddSupplierComponent,
  AdminHomeComponent,
  HospitalDetailsComponent,
  HospitalHomeComponent,
  AddRequestsComponent,
  RequestsHomeComponent,
  ProductDetailsComponent,
  ProductsHomeComponent,
  SupplierHomeComponent,
  SupplierDetailsComponent,
  AddProductComponent,
  SupplierProductsHomeComponent,
  SupplierRequestsHomeComponent,
  SupplierHospitalDetailsComponent,
  SupplierMessagesHomeComponent, SupplierMessageDetailsComponent,
  HospitalMessagesHomeComponent, HospitalMessageDetailsComponent,
  DonorSearchComponent
];

@NgModule({
  imports: [
    PdfViewerModule,
    NgxJsonViewerModule,
    PagesRoutingModule,
    FileUploadModule,
    ThemeModule,
    MatExpansionModule,
    MatIconModule,
    MatDialogModule,
    MatProgressBarModule,
    Ng2SmartTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatSnackBarModule,
    MatSelectModule,
    SharedModule
    ],
  declarations: [
    ...PAGES_COMPONENTS,
  ],
  providers: [
    AuthGuard,
    UploadService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  entryComponents: [HomeComponent, DocViewerComponent, BlockViewerComponent,
                    CaseHomeComponent, AddCaseComponent,
                    AddHospitalComponent, AddSupplierComponent, AdminHomeComponent,
                    HospitalDetailsComponent, HospitalHomeComponent, AddRequestsComponent,
                    RequestsHomeComponent, ProductDetailsComponent, ProductsHomeComponent,
                    SupplierHomeComponent, SupplierDetailsComponent, AddProductComponent,
                    SupplierProductsHomeComponent, SupplierRequestsHomeComponent,
                    SupplierHospitalDetailsComponent,
                    SupplierMessagesHomeComponent, SupplierMessageDetailsComponent,
                    HospitalMessagesHomeComponent, HospitalMessageDetailsComponent
                   ],
})
export class PagesModule {
}
