import { Component, OnInit, Inject, AfterViewInit, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../../services/data.service';
import { AppGlobal } from '../../../../common/app.global';
import { IHospitalRequest } from '../../../../services/IHospitalRequest';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material';
import { BlockViewerComponent } from '../../../viewer/blockViewerComponent';
import { ProfileService } from '../../../../services/profile.service';
import { IUser } from '../../../../common/models/IUser';
import * as RequestClient from 'request';

@Component({
  selector: 'gjs-add-requests',
  templateUrl: './addRequests.component.html',
  styleUrls: ['./addRequests.component.scss'],
  providers: [DataService, {
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class AddRequestsComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper: MatStepper;
  isReadOnly = false;
  public loading = false;
  public isSubmitDisabled = false;
  private user = <IUser>{};
  private requestBlockHistory = [];
  private donorData = [];
  pledgedHistory = [];
  countryList: Array<any> = [
    { name: 'UAE', states: ['Dubai', 'Abu Dhabi', 'Sharjah', 'Umm Al Qaiwain', 'Fujairah', 'Ajman', 'Ras Al Khaimah'] },
    { name: 'USA', states: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
    'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
    'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'] },
    {name: 'INDIA', states: [ 'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttarakhand',
    'Uttar Pradesh',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Lakshadweep',
    'Puducherry']}
  ];
  states: Array<any>;

  constructor(
    private zone: NgZone, public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddRequestsComponent>, public dialog: MatDialog,
    private dataService: DataService, private config: AppGlobal,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: IHospitalRequest) {
      this.user = this.profileService.setUpProfile();
      if (data.status !== 'New') {
        this.isReadOnly = true;
        if (this.data.receivedQuantity === '0') {
           this.data.receivedQuantity = null;
        }
      }
      this.states = this.countryList.find(con => con.name === data.country).states;
  }

  async ngOnInit() {
    if (this.data.status !== 'New' && this.data.requestID) {
      this.loadRequestHistory();
      // if (this.data.status !== 'New' && this.data.requestID) {
      //   this.loadDonorData();
      // }
    }
  }

  changeCountry(event) {
    this.states = this.countryList.find(con => con.name === event.value).states;
  }

  loadRequestHistory() {
    this.loading = true;
    this.dataService.getRequestHistory(this.data.requestID).subscribe((res) => {
      console.log(res);
      this.requestBlockHistory = res;
      this.loading = false;
    }, err => {
      console.log(err);
      this.loading = false;
    });
  }

  // loadDonorData() {
  //   this.loading = true;
  //   this.dataService.getHospitalRequestDonors(this.data.requestID).subscribe((donors) => {
  //     console.log('donors: ' + donors);
  //     this.donorData = donors;
  //     this.loading = false;
  //   }, err => {
  //     this.showSnackBar(err.error.message);
  //     this.loading = false;
  //   });
  // }

  ngAfterViewInit() {
  }

  // Dialog Close
  onCancel(): void {
    this.dialogRef.close();
  }

  // Show Confirmation Messages
  showSnackBar(msg) {
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

  // Create Request
  registerRequest(data) {
    this.data.status = 'Requested';
    console.log('Registered Data Sent:\n' + JSON.stringify(this.data));
    this.dataService.registerHospitalRequest(this.data).subscribe((requests) => {
      this.showSnackBar('Requested Successfully');
      this.dialogRef.close();
      console.log('Registered Data Received:\n' + JSON.stringify(requests));
    }, err => {
      console.log(err.error.message);
      this.showSnackBar('Please try again ' + err.error.message);
    });
  }

  // Donate
  updateRequest() {
    if (this.data.receivedQuantity ) {
      // tslint:disable-next-line:radix
      if (parseInt(this.data.requiredQuantity) > parseInt(this.data.receivedQuantity)) {
        this.data.status = 'Partly Fulfilled';
      } else {
        this.data.status = 'Fulfilled';
      }
      if (!this.data.requiredItemGrade) {
        this.data.requiredItemGrade = '';
      }

      if (!this.data.requiredByDate) {
        this.data.requiredByDate = '';
      }

      this.data.lastUpdatedByName = this.user.fullName;
      this.data.lastUpdatedByEmail = this.user.email;
      this.data.lastUpdatedDate = new Date().toISOString().split('T')[0];
      console.log('update request sent:\n' + JSON.stringify(this.data));
      this.dataService.updateRequest(this.data).subscribe((requests) => {
      this.showSnackBar('Successfully Updated. Thank you!!');
      this.dialogRef.close();
      console.log('Data Received:\n' + JSON.stringify(requests));
    }, err => {
      console.log(err.error.message);
      this.showSnackBar('Please try again ' + err.error.message);
    });
  }
  }

  // Close Request
  closeRequest() {
    this.data.status = 'Closed';
    this.data.lastUpdatedByName = this.user.fullName;
    this.data.lastUpdatedByEmail = this.user.email;
    this.data.lastUpdatedDate = new Date().toISOString().split('T')[0];
    console.log('Updated Supplier Data:\n' + JSON.stringify(this.data));
    this.dataService.closeRequest(this.data).subscribe((requests) => {
      this.sendEmailToDonors(this.donorData);
      this.showSnackBar('Request Closed');
      this.dialogRef.close();
      console.log('Data Received:\n' + JSON.stringify(requests));
    }, err => {
      console.log(err.error.message);
      this.showSnackBar('Please try again ' + err.error.message);
    });
  }

  deleteRequest() {
    if (this.data.requestID) {
      this.loading = true;
      this.dataService.deleteRequest(this.data.requestID).subscribe((res) => {
        this.showSnackBar('Successfully Deleted the Request');
      }, err => {
        this.showSnackBar(err.error.message);
        this.loading = false;
        this.showSnackBar('Error Deleting the Request, Please try again');
      });
    }
  }

  sendEmailToDonors(donors) {
    donors.forEach(donor => {
      const flowData = {
        'donorEmail' : donor.donorEmail,
        'donorFirstName': donor.donorFirstName,
        'donorLastName': donor.donorLastName,
        'entity': 'Hospital'
      };
      RequestClient( {
        url: this.config.sendEmailFlow,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: flowData,
        json: true
      }, (err, xres, body) => {
        if (err ) {
          console.log('err' + err);
        } else if ( xres && xres.statusCode !== 200) {
          console.log('xres' + JSON.stringify(xres));
        } else {
          console.log(body);
        }
      });
    });
  }

  // Open Request Blocks
  openBlockViewer(blockStatus) {
    let results = [];
    const searchField = 'status';
    const searchVal = blockStatus;
    console.log('Block of status: ' + blockStatus);
    if (this.requestBlockHistory && blockStatus !== 'ViewAllBlocks') {
      for (let i = 0; i < this.requestBlockHistory.length; i++) {
        if (this.requestBlockHistory[i][searchField] === searchVal) {
          results.push(this.requestBlockHistory[i]);
        }
      }
    }
     if (blockStatus === 'ViewAllBlocks' ) {
      results = this.requestBlockHistory;
     }
     console.log(results);
      this.dialog.open(BlockViewerComponent, {
        width: '900px',
        height: '800px',
        data: results,
        panelClass: 'custom-modalbox'
      });

  }

  fixDate(date) {
    if (!date) {
      return date;
    }
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

}
