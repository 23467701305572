import { Component, OnInit, Inject, AfterViewInit, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../services/data.service';
import { AppGlobal } from '../../../common/app.global';
import { IHospitalRequest } from '../../../services/IHospitalRequest';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material';
import { ProfileService } from '../../../services/profile.service';
import * as RequestClient from 'request';

@Component({
  selector: 'gjs-add-requests',
  templateUrl: './addRequests.component.html',
  styleUrls: ['./addRequests.component.scss'],
  providers: [DataService, {
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class AddRequestsComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper: MatStepper;
  isReadOnly = false;
  public loading = false;
  public isSubmitDisabled = false;
  public isVerifiedDtTm = false;
  pledgedHistory = [];
  countryList: Array<any> = [
    { name: 'UAE', states: ['Dubai', 'Abu Dhabi', 'Sharjah', 'Umm Al Qaiwain', 'Fujairah', 'Ajman', 'Ras Al Khaimah'] },
    { name: 'USA', states: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
    'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
    'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'] },
    {name: 'INDIA', states: [ 'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttarakhand',
    'Uttar Pradesh',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Lakshadweep',
    'Puducherry']}
  ];
  states: Array<any>;

  constructor(
    private zone: NgZone, public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddRequestsComponent>, public dialog: MatDialog,
    private dataService: DataService, private config: AppGlobal,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: IHospitalRequest) {
      if (data.status !== 'New') {
        this.isReadOnly = true;
        if (this.data.receivedQuantity === '0') {
           this.data.receivedQuantity = null;
        }
      }
      this.states = this.countryList.find(con => con.name === data.country).states;
  }

  async ngOnInit() {
  }

  isVerifiedDtTmChecked(event) {
    console.log(event.checked);
    if(event.checked) {
      this.data.verifiedDtTm  = new Date().toLocaleString("en-US", {timeZone: "Asia/Calcutta"}); // new Date().toLocaleDateString()+ ' ' + new Date().toLocaleTimeString();
    } else {
      this.data.verifiedDtTm = '';
    }
  }

  changeCountry(event) {
    this.states = this.countryList.find(con => con.name === event.value).states;
  }

  // loadDonorData() {
  //   this.loading = true;
  //   this.dataService.getHospitalRequestDonors(this.data.requestID).subscribe((donors) => {
  //     console.log('donors: ' + donors);
  //     this.donorData = donors;
  //     this.loading = false;
  //   }, err => {
  //     this.showSnackBar(err.error.message);
  //     this.loading = false;
  //   });
  // }

  ngAfterViewInit() {
  }

  // Dialog Close
  onCancel(): void {
    this.dialogRef.close();
  }

  // Show Confirmation Messages
  showSnackBar(msg) {
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

  // Create Request
  registerRequest(data) {
    this.loading = true;
    this.data.status = 'Requested';
    console.log('Registered Data Sent:\n' + JSON.stringify(this.data));
    this.dataService.registerHospitalRequest_open(this.data).subscribe((requests) => {
      this.showSnackBar('Requested Successfully');
      this.dialogRef.close(data);
      this.loading = false;
      console.log('Registered Data Received:\n' + JSON.stringify(requests));
    }, err => {
      console.log(err);
      this.loading = false;
      this.showSnackBar('Error creating the request, Please contact admin');
    });
  }

  fixDate(date) {
    if (!date) {
      return date;
    }
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

}
