import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
    {
      title: 'HOME',
      icon: 'nb-home',
      link: '/pages/home',
      home: true,
    }
  ];
