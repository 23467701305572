import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IUser } from '../../../../common/models/IUser';
import { ProfileService } from '../../../../services/profile.service';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatDialog } from '@angular/material';
import { DataService } from '../../../../services/data.service';
import { IHospitalRequest } from '../../../../services/IHospitalRequest';
import { AddRequestsComponent } from '../addRequests/addRequests.component';
import { DomSanitizer } from '@angular/platform-browser';
import { IHospital } from '../../../../services/IHospital';
import { HospitalDetailsComponent } from '../../hospitalDetails/hospitalDetails.component';
import { DonorInformationComponent } from '../../../../@theme/components/donorInformation/donorInformation.component';
import { NbToastrService } from '@nebular/theme';
@Component({
    selector: 'gjs-requestshome',
    templateUrl: './requestsHome.component.html',
    styleUrls: ['./requestsHome.component.scss'],
    providers: [DataService]
})

export class RequestsHomeComponent implements OnInit, OnDestroy {

  public tableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'viewRequestDetails',
          // tslint:disable-next-line:max-line-length
          title: this.sanitizer.bypassSecurityTrustHtml(` <button style='margin-right:16px'><i style='' title='Click to see request details' class="nb-edit"></i></button>`)
        },
        {
          name: 'viewDonors',
          // tslint:disable-next-line:max-line-length
          title: this.sanitizer.bypassSecurityTrustHtml(` <button style='margin-right:16px'><i style='' title='Click to see donors' class="nb-person"></i></button>`)
        },
      ],
      sort: true,
      position: 'right',
    },
    pager : {
      display : true,
      perPage : 20
    },
    columns: {
      // requestID: {
      //   title: 'Request#'
      // },
      status: {
        title: 'Request Status',
        sort: true,
        filter: {
          type: 'list',
          defaultValue: 'Requested',
          config: {
            selectText: 'All',
            list:  [
              { value: 'Requested', title: 'Requested' },
              { value: 'Pending', title: 'Pending' },
              { value: 'Partly Fulfilled', title: 'Partly Fulfilled' },
              { value: 'Fulfilled', title: 'Fulfilled' },
              { value: 'Closed', title: 'Closed' },
            ],
          }
        },
      },      
      patientName: {
        title: 'Patient Name',
        sort: true
      },
      state: {
        title: 'State',
        sort: true
      },
      city: {
        title: 'City',
        sort: true
      },
      requiredItem: {
        sort: true,
        title: 'Requested Item'
      },
      requiredQuantity: {
        sort: true,
        title: 'Required Quantity'
      },
      requiredItemGrade: {
        sort: true,
        title: 'Grade/Certification'
      },
      requiredByDate: {
        title: 'Required By',
        type: Date,
        sortDirection: 'desc',
        sort: true,
      },
      verifiedDtTm: {
        title: 'Verified On',
        sort: true,
      },
      lastUpdatedDate: {
        title: 'Last Update',
        sort: true, 
      }
    }
  };

  public donorTableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'viewDonationDetails',
          // tslint:disable-next-line:max-line-length
          title: this.sanitizer.bypassSecurityTrustHtml(` <button><i style='' title='Click to see donation details' class="nb-edit"></i></button>`)
        }
      ],
      position: 'right',
    },
    pager : {
      display : true,
      perPage : 5
    },
    columns: {
      // pledgeID: {
      //   title: 'Donor#'
      // },
      donorFirstName: {
        title: 'First Name'
      },
      donorLastName: {
        title: 'Last Name'
      },
      donorOrganization: {
        title: 'Organization'
      },
      donorEmail: {
        title: 'Email'
      },
      donorPhone: {
        title: 'Phone'
      },
      pledgedQuantity: {
        title: 'Pledged Quantity'
      },
      donatedQuantity: {
        title: 'Donated Quantity'
      },
    }
  };

  public error: any = undefined;
  public browserLang: string;
  private user = <IUser>{};
  private subscription: Subscription;
  public data;
  public loading = false;
  public donorDataLoading = false;
  public tableData = [ ];
  public donorData = [ ];
  public requestID;

  constructor(
    private toastrService: NbToastrService,
    private zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog,
    private translate: TranslateService, private profileService: ProfileService,
    private dataService: DataService, private sanitizer: DomSanitizer) {
      this.user = this.profileService.setUpProfile();

    }

    translateMenuItems(): any {
      throw new Error('Method not implemented.');
    }

    loadData() {
      this.loading = true;
      this.dataService.getHospitalRequests(this.profileService.getGroupId()).subscribe((requests) => {
        this.tableData = requests;
        this.loading = false;
      }, err => {
        this.showSnackBar(err.error.message);
        this.loading = false;
        this.showSnackBar('Please refresh the page to see the changes');
      });
    }

    loadHospitalMsgs() {
      this.loading = true;
      this.dataService.getHospitalNewMessages(this.profileService.getGroupId()).subscribe((res) => {
        this.loading = false;
        console.log('getHospitalNewMessages res:' + res);
        if (res.length > 0) {
          this.showToast(res);
          }
      }, err => {
        this.showSnackBar(err.error.message);
        this.loading = false;
        this.showSnackBar('Please refresh the page to see the changes');
      });
    }

    ngOnInit(): void {
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.use(event.lang);
      });
      this.loadData();
      this.loadHospitalMsgs();
    }

    ngOnDestroy() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }

    createHospitalRequest() {
      this.loading = true;
      this.dataService.getHospitalRecord(this.profileService.getGroupId()).subscribe((hospitalRecord: IHospital) => {
        const data: IHospitalRequest = {
          status: 'New',
          requestID: this.generateRequestID(),
          hospitalID: hospitalRecord.hospitalID,
          hospitalName: hospitalRecord.hospitalName,
          hospitalPhoneNo: hospitalRecord.hospitalPhoneNo,
          hospitalEmail: hospitalRecord.hospitalEmail,
          country: hospitalRecord.country,
          state: hospitalRecord.state,
          city: '',
          patientName: '',
          careHospital: '',
          verifiedDtTm: '',
          notes: '',
          requiredItem: '',
          requiredQuantity: '',
          requiredItemGrade: '',
          pledgedQuantity: '',
          receivedQuantity: '',
          requiredByDate: '',
          pledgedBy: '',
          pledgedByEmail: '',
          pledgedByPhoneNo: '',
          createdOn: new Date().toISOString().split('T')[0] ,
          createdByName: this.user.fullName,
          createdByEmail: this.user.email,
          lastUpdatedDate: new Date().toISOString().split('T')[0] ,
          lastUpdatedByName: this.user.fullName,
          lastUpdatedByEmail: this.user.email
        };
        this.loading = false;

        const dialogRef = this.dialog.open(AddRequestsComponent, {
          data: data
        });

        dialogRef.afterClosed().subscribe(newRequest => {
          if (newRequest) {
              this.loading = true;
             setTimeout(() => {
              this.loadData();
             }, 5000);
            }
          });
          this.loading = false;
        });
        this.loading = false;
      }

      generateRequestID() {
        let dt = new Date().getTime();
        const uuid = 'xxxxxxxx'.replace(/[xy]/g, function(c) {
            // tslint:disable-next-line:no-bitwise
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            // tslint:disable-next-line:no-bitwise
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
      }

      onCustomAction(event) {
        switch ( event.action) {
          case 'viewRequestDetails':
            this.viewDetails(event);
            break;
          case 'viewDonors':
            this.viewDonors(event);
            break;
        }
      }

      viewDetails(event) {
        const requestData = event.data;
        // if (requestData.receivedQuantity !== '0') {
        // requestData.pledgedQuantity = requestData.receivedQuantity;
        // }
        const dialogRef = this.dialog.open(AddRequestsComponent, {
          width: '1050px',
          height: '100%',
          position: { right: '0', top: '0', bottom: '0'},
          data: {...requestData},
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
           this.loading = true;
           setTimeout(() => {
            this.loadData();
           }, 5000);
          }
        });
      }

      showSnackBar(msg) {
      this.zone.run(() => {
        this.snackBar.open(msg, '', { duration: 3000 });
      });
    }

    viewDonors(event) {
      if (event.data.requestID) {
        this.donorDataLoading = true;
        this.dataService.getHospitalRequestDonors(event.data.requestID).subscribe((donors) => {
          this.donorData = donors;
          this.donorDataLoading = false;
        }, err => {
          this.showSnackBar(err.error.message);
          this.donorDataLoading = false;
          this.showSnackBar('Please refresh the donor table to see the changes');
        });
      }
    }

    onCustomDonorAction(event) {
      switch ( event.action) {
        case 'viewDonationDetails':
          this.viewDonationDetails(event);
          break;
      }
    }

    viewDonationDetails(event) {
      const pledgeData = event.data;
      const dialogRef = this.dialog.open(DonorInformationComponent, {
        height: '100%',
        position: { right: '0', top: '0', bottom: '0'},
        data: {...pledgeData},
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
         this.loading = true;
         setTimeout(() => {
          this.loadData();
         }, 5000);
        }
      });
    }

    showToast(count) {
      this.toastrService.primary(
        'You have new messages to respond',
        `New Message (${count})`,
        {
          duration: 9000,
        }
      );

  }

}
