import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { Router, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'gjs-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy {

// constructor(private router: Router) {
// }
//   callHospital() {
//     this.router.navigate(['/pages/hospital']);
//   }

// callSupplier() {
//   this.router.navigate(['/pages/supplier']);
// }

// callCovidCases() {
//   this.router.navigate(['/pages/case']);
// }

// callDonation() {
//   this.router.navigate(['/pages/donation']);
// }

  private subscription: Subscription;
  constructor (private router: Router, private profile: ProfileService) {
    const navigationExtras: NavigationExtras = {
    skipLocationChange: true
  };

  if (!this.profile.isUserAuthenticated()) {
    this.router.navigate(['/donate']);
  } else {
      this.subscription = this.profile.setRole().subscribe(role => {
        switch (role) {
          case 'Hospital Admin':
            this.router.navigate(['/pages', 'hospital'], navigationExtras);
            break;
          case 'Supplier Admin':
            this.router.navigate(['/pages', 'supplier'], navigationExtras);
            break;
          case 'Case Admin':
            this.router.navigate(['/pages', 'case'], navigationExtras);
            break;
          case 'Administrator':
            this.router.navigate(['/pages', 'admin'], navigationExtras);
            break;
          case '':
            const navExtras: NavigationExtras = {
              skipLocationChange: true,
              queryParams: {
                error: 'Thank you for signing up.',
                submsg: 'Your registration is successful!',
                desc: 'An administrator will review your registration'
              }
            };
            this.router.navigate(['pageNotFound'],  navExtras);
            break;
          default:
            break;
        }
      }, err => {
        console.log(err);
        const navExtras: NavigationExtras = {
          skipLocationChange: true,
          queryParams: {
            desc: err
          }
        };
        this.router.navigate(['pageNotFound'],  navExtras);
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
