
import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { slideInDownAnimation } from './animations';
@Component({
  selector: 'gjs-a2p',
  template: `<router-outlet></router-outlet>`,
  animations: [slideInDownAnimation]
})
export class AppComponent implements OnInit {

  constructor(private analytics: AnalyticsService, private translate: TranslateService) {
    this.analytics.trackPageViews();
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.translate.use(this.translate.getBrowserLang());
  }


  ngOnInit(): void {
    this.analytics.trackPageViews();
  }
}
