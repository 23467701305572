import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LandingComponent } from './@theme/components/landing/landing.component';
import { PageNotFoundComponent } from './@theme/components/pageNotFound/pageNotFound.component';
import { MsalGuard } from '@azure/msal-angular';
import { LogoutComponent } from './@theme/components/logout/logout.component';
import { PagesModule } from './pages/pages.module';
import { environment } from 'src/environments/environment';
import { DonationHomeComponent } from './@theme/components/donationHome/donationHome.component';
import { ContactUsComponent } from './@theme/components/contactus/contactus.component';
import { HospitalProfileComponent } from './@theme/components/hospitalProfile/hospitalProfile.component';

const routes: Routes = [
  { path: '', redirectTo: 'donate', pathMatch: 'full' },
  {
    path: 'pages',
    loadChildren: () => PagesModule,
    canActivate : [MsalGuard]
  },
  {
    path: 'landing',
    component: LandingComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'pageNotFound',
    component: PageNotFoundComponent
  },
  {
    path: 'donate',
    component: DonationHomeComponent
  },
  {
    path: 'contactus',
    component: ContactUsComponent
  },
  {
    path: 'hospitalProfile',
    component: HospitalProfileComponent
  },
  {
    path: 'search',
    redirectTo: '/pages/search'
  },
  { path: '**', redirectTo: 'pageNotFound' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
