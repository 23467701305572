export class IHospitalRequest {
    public	status: string;
    public  requestID: string;
    public  hospitalID: string; // Request ID
    public	hospitalName: string; // Requested By Hospital/Volunteer/Person
    public	hospitalPhoneNo: string; // Contact No
    public  hospitalEmail: string; // Contact Email
    public  patientName: string; // Required By
    public  careHospital: string; // Care or Hospital
    public  verifiedDtTm: string; // Verified on
    public  notes: string; // notes or comments
    public  country: string;
    public  state: string;
    public  city: string;
    public  requiredItem: string;
    public  requiredQuantity: string;
    public  requiredItemGrade: string;
    public  requiredByDate: string;
    public  pledgedQuantity: string;
    public  receivedQuantity: string;
    public  pledgedBy: string; // pledgers
    public  pledgedByEmail: string;
    public  pledgedByPhoneNo: string;
    public  createdOn: string;
    public  createdByName: string;
    public  createdByEmail: string;
    public  lastUpdatedDate: string;
    public  lastUpdatedByName: string;
    public  lastUpdatedByEmail: string;
}
