import { Component, OnInit, NgZone } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { Router } from '@angular/router';
import { IMessage } from '../../../../services/IMessage';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { SupplierMessageDetailsComponent } from '../supplierMessageDetails/supplierMessageDetails.component';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ProfileService } from '../../../../services/profile.service';
import { IUser } from '../../../../common/models/IUser';

@Component({
  selector: 'gjs-suppliermessages-home',
  templateUrl: './suppMsgsHome.component.html',
  styleUrls: ['./suppMsgsHome.component.scss'],
  providers: [DataService]
})
export class SupplierMessagesHomeComponent implements OnInit {

  tableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    pager : {
      display : true,
      perPage : 5
    },
    columns: {
      date: {
        title: 'Date',
        width: '15%',
        type: 'date',
        sort: true, sortDirection: 'desc',
        valuePrepareFunction: (date) => {
          if (date) {
          return new DatePipe('en-US').transform(date, 'MM-dd-yyyy');
          }
          return null;
          },
      },
      status: {
        title: 'Status',
        width: '15%',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          if (row.status === 'Hospital Sent') {
            return 'Received';
          } else if (row.status === 'Supplier Replied') {
            return 'Replied';
          } else {
            return '';
                 }
        },
      },
      hospitalName: {
        title: 'Hospital',
        width: '15%',
        type: 'string',
      },
      productName: {
        title: 'Product',
        width: '15%',
        type: 'string',
      },
      message: {
        title: 'Message',
        width: '80%',
        type: 'string'
      },
      Action:
      {
        title: 'Action',
        width: '10%',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
            if (row.status === 'Hospital Sent') {
              // tslint:disable-next-line:max-line-length
              return this.sanitizer.bypassSecurityTrustHtml(`<button style='background-color:lightgreen;font-weight: 500;line-height:1.25;padding: 0.75rem 1.5rem;font-size: 1.25rem;border-radius: 0.375rem;border: 2px solid transparent;vertical-align: middle;display: inline-block;text-shadow: none;font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;'>Reply</button>`);
            } else {
              return '';
                   }
          },
        filter: false
      },
    },
  };
  public messages = [];
  public loading = false;
  private user = <IUser>{};

  constructor(private router: Router, private dataService: DataService, private profileService: ProfileService,
              private sanitizer: DomSanitizer, public dialog: MatDialog,
              private zone: NgZone, public snackBar: MatSnackBar) {
  this.user = this.profileService.setUpProfile();
               }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.dataService.getSupplierMessages(this.profileService.getGroupId()).subscribe((products) => {
      this.messages = products;
      this.loading = false;
    }, err => {
      this.showSnackBar(err.error.message);
      this.loading = false;
      this.showSnackBar('Please refresh the page to see the changes');
    });
  }

  genarateID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx'.replace(/[xy]/g, function(c) {
      // tslint:disable-next-line:no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  goToViewMessage(event): void {
    if (event.data.status === 'Hospital Sent' || event.data.status === 'Supplier Replied') {
    const dialogRef = this.dialog.open(SupplierMessageDetailsComponent, {
      width: '50%',
      data: {...event.data}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
         this.loading = true;
         setTimeout(() => {
          this.loadData();
         }, 5000);
        }
      });
    }
  }

  showSnackBar(msg) {
    this.zone.run(() => {
    this.snackBar.open(msg, '', { duration: 3000 });
      });
  }

}
