import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'gjs-hospitalProfile',
    templateUrl: './hospitalProfile.component.html',
    styleUrls: ['./hospitalProfile.component.scss'],
    providers: []
})
export class HospitalProfileComponent implements OnInit {
    ngOnInit(): void {
        throw new Error("Method not implemented.");
    }
}