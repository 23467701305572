import { MsalInterceptor, MsalService, BroadcastService } from '@azure/msal-angular';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap} from 'rxjs/operators';
import { appMsalConfig } from '../settings/appsettings';
import { MSALError } from './msal.error';

@Injectable()
export class TokenInterceptor {

    constructor(private auth: MsalService, private broadcastService: BroadcastService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const scopes = this.auth.getScopesForEndpoint(req.url);
        this.auth.verbose('Url: ' + req.url + ' maps to scopes: ' + scopes);
        if (scopes === null) {
            return next.handle(req);
        }
        const tokenStored = this.auth.getCachedTokenInternal(scopes);
        if (tokenStored && tokenStored.token) {
          req = req.clone({
              setHeaders: {
                    Authorization: `Bearer ${tokenStored.token}`,
                }
            });
            return next.handle(req).do(event => {}, err => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    const scopes1 = this.auth.getScopesForEndpoint(req.url);
                    const tokenStored1 = this.auth.getCachedTokenInternal(scopes1);
                    if (tokenStored1 && tokenStored1.token) {
                        this.auth.clearCacheForScope(tokenStored1.token);
                    }
                    const msalerr = new MSALError(JSON.stringify(err), '', JSON.stringify(scopes1));
                    this.broadcastService.broadcast('msal:notAuthorized', msalerr);
                }
            });
        } else {
            return from(this.auth.acquireTokenSilent(scopes).then(token => {
                const JWT = `Bearer ${token}`;
                return req.clone({
                    setHeaders: {
                        Authorization: JWT,
                    },
                });
            })).pipe(mergeMap(req1 => next.handle(req1).do(event => {}, err => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    const scopes2 = this.auth.getScopesForEndpoint(req.url);
                    const tokenStored2 = this.auth.getCachedTokenInternal(scopes2);
                    if (tokenStored2 && tokenStored2.token) {
                        this.auth.clearCacheForScope(tokenStored2.token);
                    }
                    const msalError = new MSALError(JSON.stringify(err), '', JSON.stringify(scopes2));
                    this.broadcastService.broadcast('msal:notAuthorized', msalError);
                }
            }))); // calling next.handle means we are passing control to next interceptor in chain
        }
    }
}
