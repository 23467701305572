import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IUser } from '../../../../common/models/IUser';
import { ProfileService } from '../../../../services/profile.service';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AddProductComponent } from '../addProducts/addProduct.component';
import { DatePipe } from '@angular/common';
import { DataService } from '../../../../services/data.service';
import { IProduct } from '../../../../services/IProduct';
import { NbToastrService } from '@nebular/theme';
@Component({
    selector: 'gjs-products-home',
    templateUrl: './productsHome.component.html',
    styleUrls: ['./productsHome.component.scss'],
    providers: [DataService]
})
export class ProductsHomeComponent implements OnInit, OnDestroy {

  public error: any = undefined;
  public browserLang: string;
  private user = <IUser>{};
  private subscription: Subscription;
  public data;
  public loading = false;

  public tableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    pager : {
      display : true,
      perPage : 5
    },
    columns: {
      // productID: {
      //   title: 'Product #'
      // },
      productName: {
        title: 'Product Name'
      },
      productCode: {
        title: 'Product Code'
      },
      productGrade: {
        title: 'Product Grade'
      },
      onHandQuantity: {
        title: 'On Hand Qty'
      },
      productionEstimatedDate: {
        title: 'Production Estimated Date',
        type: 'date',
        valuePrepareFunction: (date) => {
          if (date) {
            return new DatePipe('en-US').transform(date, 'MM-dd-yyyy');
          }
          return null;
        },
      }
    }
  };

  public tableData = [ ];

  constructor(
      private toastrService: NbToastrService,
      private zone: NgZone, public snackBar: MatSnackBar,
      private translate: TranslateService,
      private profileService: ProfileService, public dialog: MatDialog,
      private dataService: DataService) {
        this.user = this.profileService.setUpProfile();
    }

    translateMenuItems(): any {
      throw new Error('Method not implemented.');
    }

    loadData() {
      this.loading = true;
      this.dataService.getSupplierProducts(this.profileService.getGroupId()).subscribe((products) => {
        this.tableData = products;
        this.loadSupplierMsgs();
        this.loading = false;
      }, err => {
        this.showSnackBar(err.error.message);
        this.loading = false;
        this.showSnackBar('Please refresh the page to see the changes');
      });
    }

    loadSupplierMsgs() {
      this.loading = true;
      this.dataService.getSupplierNewMessages(this.profileService.getGroupId()).subscribe((res) => {
        this.loading = false;
        console.log('getSupplierNewMessages res:' + res);
        if (res.length > 0) {
        this.showToast(res);
        }
      }, err => {
        this.showSnackBar(err.error.message);
        this.loading = false;
        this.showSnackBar('Please refresh the page to see the changes');
      });
    }

    ngOnInit(): void {
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.use(event.lang);
      });
      this.loadData();
    }

    ngOnDestroy() {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
    }

    registerProduct() {
      this.dataService.getSupplierRecord(this.profileService.getGroupId()).subscribe((supplier) => {
        const data: IProduct = {
          status: 'New',
          supplierID: supplier.supplierID,
          supplierName: supplier.supplierName,
          productID: this.getProductID(),
          productName: '',
          productCode: '',
          productGrade: '',
          productDI: '',
          productUDI: '',
          onHandQuantity: '',
          productionEstimatedQuantity: '',
          productionEstimatedDate: '',
          isFDAApproved: '',
          createdOn: '',
          createdByName: this.user.fullName,
          lastUpdatedDate: '',
          lastUpdatedByName: this.user.fullName,
        };

        const dialogRef = this.dialog.open(AddProductComponent, {
          height: '100%',
          position: { right: '0', top: '0', bottom: '0'},
          data: data,
        });

        dialogRef.afterClosed().subscribe(newProduct => {
          if (newProduct) {
            this.loading = true;
            setTimeout(() => {
              this.loadData();
              this.loading = false;
            }, 5000);
          }
        });
      }, err => {
        this.showSnackBar(err.error.message);
        this.loading = false;
      });
    }

    getProductID() {
      let dt = new Date().getTime();
      const uuid = 'xxxxxxxx'.replace(/[xy]/g, function(c) {
        // tslint:disable-next-line:no-bitwise
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        // tslint:disable-next-line:no-bitwise
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    }

    viewProductDetails(event) {
      const dialogRef = this.dialog.open(AddProductComponent, {
        height: '100%',
        position: { right: '0', top: '0', bottom: '0'},
        data: {...event.data},
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loading = true;
          setTimeout(() => {
            this.loadData();
          }, 5000);
        }
      });
    }

    showSnackBar(msg) {
    this.zone.run(() => {
      this.snackBar.open(msg, '', { duration: 3000 });
    });
  }

  showToast(count) {
    this.toastrService.primary(
      'You have new messages to respond',
      `New Message (${count})`,
      {
        duration: 9000,
      }
    );
  }
}
