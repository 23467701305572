import { Component, Inject, ElementRef, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, AUTOCOMPLETE_OPTION_HEIGHT } from '@angular/material';


@Component({
    selector: 'gjs-case-blockviewer',
    template: `
    <div mat-dialog-content align="right">
    <button type="button" (click)="OK()" class="btn btn-warning btn-s">Close</button>
    </div>
    <ngx-json-viewer [json]="data"></ngx-json-viewer> <br>

    <mat-spinner style="margin:0 auto;" *ngIf='!data'></mat-spinner>
    `,
    styleUrls: ['./viewer.component.scss'],
  })

  export class BlockViewerComponent implements AfterViewInit, OnInit {

    constructor (
        public dialogRef: MatDialogRef<BlockViewerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        if (data) {
            data = JSON.stringify(this.data);
            // console.log(data);
         }
      }
    ngOnInit() {

    }

    ngAfterViewInit() {

     }

     OK(): void {
        this.dialogRef.close();
      }

  }
